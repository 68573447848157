export type Nullable<T> = T | null;
export * from './db-query';

export enum IdaAssetCategoryEnum {
  none = 0,
  dataset = 1,
  api = 2,
  dataReport = 3,
  dataApp = 4,
  compute = 5,
}

export enum IdaAssetUpdateCycleTypeEnum {
  static = 1,
  instant = 2,
  period = 3,
  other = 4,
}

export type IDADataDetailResponse = {
  AssetCode: string; // 资产编码
  AssetName: string; // 资产名称
  AssetEnName: string; // 资产英文简称
  Category: IdaAssetCategoryEnum; //资产的类型
  IsDeleted: boolean; // 资产状态(0:正常,1:已删除)
  ImmediatelySupply: boolean;
  SupplyTime: string;
  DataScale: string;
  IndustryTitle: string; // 行业名称
  Summary: string; // 资产介绍
  AnnexUrls: string[];
  UserCategories: string; // 使用对象
  UpdateCycleType: IdaAssetUpdateCycleTypeEnum;
  TimeSpan: string;
  CreatedTime: number;
  UpdatedTime: number;
  DataAsset: {
    Name: string;
    Type: string;
    Length: number;
    IsPrimaryKey: number;
    IsNotNull: number;
    PrivacyQuery: number;
  }[];
  ApiAsset: {
    Header: string,
    Params: string,
    Response: string,
    Method: string,
    ResponseType: 'Json' | 'XML',
    Url: string
  }[];
};
