import React, {  useEffect,  useState } from 'react';
import { Row, Col } from 'tea-component';
import style from './index.module.scss';
import { CrossOverviewData } from '@src/utils/apis';
import { numberToEnUS } from '@src/utils/tools';
import { CrossOverviewDataInfo } from '@src/models';
import { useParams } from 'react-router-dom';
import shortestIcon from '../../../../assets/imgs/shortestIcon.png';
import longestIcon from '../../../../assets/imgs/longestIcon.png';
import averageIcon from '../../../../assets/imgs/averageIcon.png';
import connectedIcon from '../../../../assets/imgs/connectedIcon.png';
import totalSyncIcon from '../../../../assets/imgs/totalSyncIcon.png';
import totalNumIcon from '../../../../assets/imgs/totalNumIcon.png';

export default function Statistics() {
  const { chainId } = useParams();
  const [data, setData] = useState<CrossOverviewDataInfo | null>(null);
  useEffect(() => {
    if(chainId) {
      CrossOverviewData({ ChainId: chainId }).then((res) => {
        setData(res.Data);
      });
    }
  }, [chainId]);


  return (
    <>
      <section className={style.block}>
        <Row>
          <Col span={4}>
            <div className={style.col}>
              <div className={style.icon_c}>
                <img src={shortestIcon} className={style.icon_svg}/>
              </div>
              <div className={style.block_item}>
                <p className={style.block_count}>{numberToEnUS(data?.ShortestTime)}<span className={style.unit}>s</span></p>
                <p className={style.block_name}>最短完成时间</p>
                <p className={style.block_name}>(当月)</p>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className={style.col}>
              <div className={style.icon_c}>
                <img src={longestIcon} className={style.icon_svg}/>
              </div>
              <div className={style.block_item}>
                <p className={style.block_count}>{numberToEnUS(data?.LongestTime)}<span className={style.unit}>s</span></p>
                <p className={style.block_name}>最长完成时间</p>
                <p className={style.block_name}>(当月)</p>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className={style.col}>
              <div className={style.icon_c}>
                <img src={averageIcon} className={style.icon_svg}/>
              </div>
              <div className={style.block_item}>
                <p className={style.block_count}>{numberToEnUS(data?.AverageTime)}<span className={style.unit}>s</span></p>
                <p className={style.block_name}>平均完成时间</p>
                <p className={style.block_name}>(当月)</p>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className={style.col}>
              <div className={style.icon_c}>
                <img src={connectedIcon} className={style.icon_svg}/>
              </div>
              <div className={style.block_item}>
                <p className={style.block_count}>{numberToEnUS(data?.SubChainNum)}</p>
                <p className={style.block_name}>已接入子链数</p>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className={style.col}>
              <div className={style.icon_c}>
                <img src={totalSyncIcon} className={style.icon_svg}/>
              </div>
              <div className={style.block_item}>
                <p className={style.block_count}>{numberToEnUS(data?.TotalBlockHeight)}</p>
                <p className={style.block_name}>总同步区块数</p>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className={style.col}>
              <div className={style.icon_c}>
                <img src={totalNumIcon} className={style.icon_svg}/>
              </div>
              <div className={style.block_item}>
                <p className={style.block_count}>{numberToEnUS(data?.TxNum)}</p>
                <p className={style.block_name}>跨链总交易数</p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}
