import React, { useEffect, useMemo, useState } from 'react';
import PageBox from '@components/ui/PageBox';
import { Form } from 'tea-component';
import detailstyle from '../detail.module.scss';
import { GetAccountDetail } from '@src/utils/apis';
import { Route, Routes, useParams } from 'react-router';
import Transaction from './components/Transaction';
import style from './index.module.scss';
import Nft from './components/Nft';
import Transfer from './components/Transfer';
import { NavLink } from 'react-router-dom';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';

export default function ChainAccount() {
  const { contractAddr = '' } = useParams();
  const userAddr = window.location.search.split('=')[1];
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId ?? '', [currentChain]);
  const [detail, setDetail] = useState<any>({});
  useEffect(() => {
    GetAccountDetail({
      Address: userAddr,
      ChainId: chainId || '',
    }).then((res) => {
      setDetail(res.Data);
    });
  }, [chainId, contractAddr]);
  return (
    <PageBox title="链账户详情">
      <div className={`${detailstyle.detail} ${style.detail}`}>
        <Form.Title>链账户基本信息</Form.Title>
        <Form hideLabel={false} fixedLabelWidth={150} layout="fixed">
          <Form.Item label="账户地址">
            <Form.Text>{detail?.Address}</Form.Text>
          </Form.Item>
          <Form.Item label="BNS信息">
            <Form.Text>{detail?.BNS || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="DID信息">
            <Form.Text>{detail?.DID || '--'}</Form.Text>
          </Form.Item>
        </Form>
      </div>
      <div className={style.navs}>
        <NavLink
          to={`/${chainId}/chainaccount/deal${window.location.search}`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          交易信息
        </NavLink>
        <NavLink
          to={`/${chainId}/chainaccount/token${window.location.search}`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          持有的Token
        </NavLink>
        <NavLink
          to={`/${chainId}/chainaccount/nft${window.location.search}`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
           持有的NFT
        </NavLink>
      </div>
      <Routes>
        <Route path="deal" element={<Transaction name={detail?.Address} />} />
        <Route path="token" element={<Transfer name={detail?.Address} type={'detail?.ContractType'}/>} />
        <Route path="nft" element={<Nft name={detail?.Address}/>} />
        {/* <Route path="*" element={<Navigate replace to="deal" />} /> */}
      </Routes>
    </PageBox>
  );
}
