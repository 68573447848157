import { Badge, Bubble, Input, Table, Tag, Tree, TreeData } from 'tea-component';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GetDatabaseList, GetDBTableList } from '@utils/apis';
import { GetDatabaseListResponse, GetDBTableListResponse } from '@src/types';
import { distinct, joinBy } from '@utils/utils';
import { registerCompletionForSQL } from '@utils/editor';
import { IDisposable } from 'monaco-editor';
import { autotip, scrollable } from 'tea-component/es/table/addons';

function getNode(nodes: any, id: any): any {
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    }
    if (node.children) {
      const result = getNode(node.children, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

const treeIDSplitter = ',';


function renderTableNameBubbleContent({ table }: {
  table: GetDBTableListResponse['tables'][0];
}) {
  return <div>
    <div className={'text-break'}>
      {table.tableDescribe && <Tag theme="primary">{table.tableDescribe}</Tag>}
      {table.tableName && <Tag theme="success">{table.tableName}</Tag>}
    </div>
    <Table
      className={'tea-mt-2n'}
      bordered
      columns={[
        {
          key: 'name',
          header: '索引名称',
          render: ({ name }) => <div>
            {name}
          </div>,
        },
        {
          key: 'type',
          header: '类型',
        },
        {
          key: 'value',
          header: '字段',
          render: ({ value }) => <div>
            {value}
          </div>,
        },
      ]}
      records={table.indexs ?? []}
      addons={[
        autotip({
          emptyText: '没有索引',
        }),
        scrollable({
          maxHeight: 200,
        }),
      ]}
    />
  </div>;
}

/**
 * 详情-左边栏
 *
 * 选取DB 库/表/字段名称
 */

export function Sidebar({ handleTableParamClick, setDBType }: {
  handleTableParamClick: (v: string) => void;
  setDBType: (type: GetDatabaseListResponse['dbType']) => void;
}) {
  const [value, setValue] = useState('');
  const [dataBaseList, setDataBaseList] = useState<GetDatabaseListResponse['dbList']>([]);

  const data = useMemo<TreeData[]>(() => {
    return dataBaseList.map(item => ({
      content:
        <Bubble content={item.database} placement={'right-start'}>
          <span
            onClick={() => handleTableParamClick(item.database)}>
            {item.chainId}
          </span>
        </Bubble>,
      id: joinBy(treeIDSplitter, item.chainId, item.database),
      expandable: true,
      children: (item.tables ?? []).map(table => ({
        id: joinBy(treeIDSplitter, item.chainId, item.database, table.tableName),
        content:
          <Bubble className={'bubble-large'} content={renderTableNameBubbleContent({ table })} placement={'left-start'}>
            <span onClick={() => handleTableParamClick(table.fullName)}>
              {
                table.tableName
              }
            </span>
          </Bubble>,
        expandable: true,
        children: (table.columns ?? []).map(column => ({
          content: <>
            <Bubble content={joinBy(',', column.describe, column.name)} placement={'right-start'}>
              <span onClick={() => handleTableParamClick(column.name)}>
                {column.name}
              </span>
            </Bubble>
            <Badge
              theme="success"
              className={'tea-ml-1n'}>{column.type}</Badge>
          </>,
          id: joinBy(treeIDSplitter, item.chainId, item.database, table.tableName, column.name),
        })),
      })),
    }));
  }, [dataBaseList, handleTableParamClick]);

  const getDBTables = useCallback(async (chainId: string) => {
    return GetDBTableList({
      chainId,
    }).then((res) => {
      setDataBaseList(state => {
        const find = state.find(item => item.chainId === chainId);
        if (find) {
          find.tables = res.data.tables;
        }
        return [...state];
      });
    });
  }, []);

  const onLoad = useCallback(async (nodeId) => {
    const node = getNode(data, nodeId);
    if (node.children) {
      return;
    }
  }, [data]);

  const handleFilter = useCallback((node: TreeData) => {
    return Boolean(node.id?.toString().match(new RegExp(value, 'i')));
  }, [value]);

  useEffect(() => {
    let disposeFn: IDisposable;
    GetDatabaseList().then(res => {
      const tables: string[] = [];
      const columns: string[] = [];
      setDataBaseList(res.data.dbList);
      setDBType(res.data.dbType);
      Promise.all(res.data.dbList.map(item => GetDBTableList({ chainId: item.chainId }))).then(resArr => {
        resArr.forEach(res => {
          setDataBaseList(state => {
            const find = state.find(item => item.chainId === res.data.chainId);
            if (find) {
              find.tables = res.data.tables;
              tables.push(...res.data.tables.map(item => item.tableName));
              columns.push(...res.data.tables.reduce((res: string[], item) => {
                res.push(...item.columns.map(i => i.name));
                return res;
              }, []));
            }
            return [...state];
          });
        });
        disposeFn = registerCompletionForSQL({
          databases: distinct(res.data.dbList.map(item => item.database)),
          tables: distinct(tables),
          columns: distinct(columns),
        });
      });
    });
    return () => {
      disposeFn?.dispose();
    };
  }, [getDBTables]);

  return <>
    <Input
      size={'full'}
      value={value}
      onChange={value => {
        setValue(value);
      }}
      placeholder={'请输入ChainId、数据库名、表名或字段名'}
    />
    <div>
      <Tree
        filterState={!!value}
        filter={handleFilter} data={data} onLoad={onLoad} className={'dbquery-tree'} height={520}/>
    </div>
  </>;
}
