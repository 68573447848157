import React from 'react';

/**
 * 系统上下文
 */
export const AppContext = React.createContext<{
  account: null | {
    name: string;
    address: string;
    token: string;
  }; // 用户钱包登录后获取的令牌
  setAccount: (account: null | {
    name: string;
    address: string;
    token: string;
  }, cb?: Function) => void;
  appConfig:{
    APP_IS_SHOW_DB_QUERY: boolean; // 是否支持SQL自定义查询
  },
}>({
      account: null,
      setAccount: () => {

      },
      appConfig:{
        APP_IS_SHOW_DB_QUERY: true,
      }
    });

export type AppContextValue = React.ContextType<typeof AppContext>;
