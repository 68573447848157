import React, { useCallback, useContext } from 'react';
import { checkInstallPlugin } from '@src/routes/main/components/tools';
import { PluginLogin } from '@src/utils/apis';
import { ChainMakerPluginInstallURL } from '@src/constant';
import { AppContext } from '@components/context';


/**
 * 插件登录后，获取账户签名进行登录
 */
export function usePluginLogin(modalRef: any, afterLoginSuccess: Function) {
  const { account, setAccount } = useContext(AppContext);
  const handleLoginClick = useCallback(() => {
    if (account) {
      return;
    }
    if (checkInstallPlugin()) {
      window.chainMaker.sendRequest('openConnect', {
        ticket: Date.now().toString(),
        chainId: '',
        body: {
          isSingle: true,
        },
      }, (res: any) => {
        const code = res.info.code;
        if (code === 1) {
          return;
        }
        const account = res.info.accounts?.[0];
        if (!account) {
          return;
        }
        PluginLogin({
          signBase64: account.signBase64,
          pubKey: account.pubKey,
        }).then(res => {
          setAccount({
            token: res.data.token,
            name: account.name,
            address: account.address,
          }, () => {
            afterLoginSuccess();
          });
        }).catch((e) => {
          console.error(e);
        });
      });
      return;
    }
    modalRef.current?.show({
      caption: '安装提醒',
      body: <div>
        请先下载长安链Web3插件钱包（ChainMaker Plugin）后再继续操作。官方渠道下载适用于无VPN的用户，谷歌应用商城下载适用于有VPN的用户，更多使用说明请
        <a
          target="_blank"
          href="https://docs.chainmaker.org.cn/dev/%E9%95%BF%E5%AE%89%E9%93%BEWeb3%E6%8F%92%E4%BB%B6.html"
          rel="noreferrer"
        >
          查看使用说明文档
        </a>。
        您还未安装钱包插件，推荐前往 Google 商店下载
        <a className={'tea-ml-1n'} target={'_blank'}
          href={ChainMakerPluginInstallURL}
          rel="noreferrer">ChainMaker Plugin</a>
      </div>,
      footerButtons: () => '',
      onCancel: () => {
      },
    });
  }, [account, afterLoginSuccess, modalRef, setAccount]);

  return {
    handleLoginClick,
  };
}
