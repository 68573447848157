import React, { useEffect, useState } from 'react';
import './component.scss';
// import { Table, TableColumn } from 'tea-component';
import { Justify, Bubble, Pagination, Input, Button } from 'tea-component';
import { NFTItem } from '@src/models';
import { GetNFTList } from '@src/utils/apis';
import { useParams } from 'react-router-dom';
import { MediaComponent } from '@components/ui/Media';
// import monkey from '@imgs/monkey.webp';
import fanstasy from '@imgs/fantasy.webp';

export default function Nft({ name }: { name?: string }) {
  const { chainId } = useParams();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [searchTokenId, setSearchTokenId] = useState<string>('');
  const [contractKey, setContractKey] = useState<string>('');
  const [searchClickCount, setSearchClickCount] = useState<number>(0);
  const [nftList, setNftList] = useState<NFTItem[]>([]);

  const getNFTList = () => {
    if (!name) {
      return;
    }
    GetNFTList({
      Offset: pageIndex - 1,
      Limit: pageSize,
      ChainId: chainId || '',
      ContractKey: contractKey,
      TokenId: searchTokenId,
      OwnerAddrs: name
    })
      .then((res: any) => {
        if (res&&res.GroupList) {
          setNftList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      });
  };
  useEffect(() => {
    getNFTList();
  }, [chainId, pageIndex, pageSize, searchClickCount,name]);

  useEffect(() => {
    return () => {
      setNftList([]);
      setRecordCount(0);
      setPageIndex(1);
      setPageSize(10);
      setSearchTokenId('');
      setContractKey('');
    };
  }, []);

  const onSearch = () => {
    setPageIndex(1);
    setSearchClickCount(searchClickCount + 1);
  };

  return (
    <>
      <div className="search-nft">
        <Justify
          right={
            <>
              <Input size='m' value={contractKey} onChange={(value) => {
                setContractKey(value);
              }} placeholder="输入合约名称/地址搜索"/>
              <Input size='m' style={{ marginLeft: 10 }} value={searchTokenId} onChange={(value) => {
                setSearchTokenId(value);
              }} placeholder="输入TokenID搜索"/>
              <Button onClick={onSearch} type="primary" style={{ marginLeft: 10 }}>搜索</Button>
            </>
          }/>
      </div>
      <div className="nft-List">
        {
          nftList?.length
            ? (nftList.map((item) => (
              <div className="nft-item" key={item.TokenId}>
                <a href={`/${chainId}/nft?tokenId=${item.TokenId}&contractAddr=${item.ContractAddr}`}>
                  <div className="nft-img">
                    <MediaComponent fileUrl={item?.ImageUrl || fanstasy} urlType={item?.UrlType} />
                  </div>
                  <p>
                    <span>Token ID：</span> <b> {item.TokenId} </b>
                  </p>
                </a>
                <a href={`/${chainId}/contract/${item.ContractAddr}`}>
                  <p>
                    <span> 合约名称： </span> <b> <Bubble content={item.ContractAddr}>{item.ContractName}</Bubble> </b>
                  </p>
                </a>
              </div>
            ))
            )
            : <>
              <div className="empty_list"></div>
            </>
        }
      </div>
      <Pagination
        stateTextVisible={true}
        recordCount={recordCount}
        pageSize={pageSize}
        pageIndex={pageIndex}
        onPagingChange={(query) => {
          setPageIndex(query.pageIndex || 1);
          setPageSize(query.pageSize || 10);
        }}
      />
    </>
  );
}
