import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'tea-component';

/**
 * 确认弹窗
 */
type ShowConfig = Partial<{
  caption: string;
  body: React.ReactFragment;
  okText: string;
  cancelText: string;
  onOK: () => void | Promise<void>;
  onCancel: () => void;
  className: string;
  footerButtons: ({ close }: {
    close: any;
  }) => React.ReactNode;
}>;

export type ConfirmModalHandle = {
  show: (config: ShowConfig) => void,
};

type ConfirmModalProp = {};

const initConfig: ShowConfig = {
  caption: '',
  body: '',
  okText: '确定',
  cancelText: '取消',
  onOK: () => {

  },
  onCancel: () => null,
  className: '',
};

const ConfirmModalContainer: React.ForwardRefRenderFunction<ConfirmModalHandle, ConfirmModalProp> = ({}, ref) => {
  const [visible, setVisible] = useState(false);

  const [modalConfig, setModalConfig] = useState<ShowConfig>(initConfig);

  const [loading, setLoading] = useState(false);

  const handleOk = useCallback(() => {
    // @ts-ignore
    const result = modalConfig.onOK();
    if (result && typeof result.then === 'function') {
      setLoading(true);
      result.finally(() => {
        setLoading(false);
        setVisible(false);
      });
    } else {
      setVisible(false);
    }
  }, [modalConfig]);

  useEffect(() => {
    if (!visible) {
      setModalConfig(initConfig);
    }
  }, [visible]);

  useImperativeHandle(ref, () => ({
    show: (config: ShowConfig) => {
      setVisible(true);
      setModalConfig({
        ...modalConfig,
        ...config,
        // @ts-ignore
        onOK: () => config?.onOK(),
        onCancel: () => {
          setVisible(false);
          config?.onCancel?.();
        },
      });
    },
  }));


  return (
    <Modal visible={visible} onClose={modalConfig.onCancel} caption={<>
      {modalConfig.caption}
    </>} className={`explorer-modal ${modalConfig.className}`} >
      <Modal.Body>
        {modalConfig.body}
      </Modal.Body>
      <Modal.Footer>
        {modalConfig.footerButtons
          ? modalConfig.footerButtons({
            close: () => {
              setVisible(false);
            },
          })
          : <>
            <Button key="cancel" onClick={modalConfig.onCancel}>
              {modalConfig.cancelText}
            </Button>
            <Button type={'primary'} key={'confirm'} onClick={handleOk} loading={loading}>
              {modalConfig.okText}
            </Button>
          </>}
      </Modal.Footer>
    </Modal>
  );
};

export const ConfirmModal = React.forwardRef(ConfirmModalContainer);
