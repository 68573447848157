import React, { useCallback, useMemo, useState } from 'react';
import style from './index.module.scss';
import Statistics from './Statistics/index';
import { CrossSearch } from '@src/utils/apis';
import { useNavigate } from 'react-router-dom';
import {  notification,  } from 'tea-component';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
import { CrossLatestTx } from './CrossLatestTx';
import { CrossLatestSubChain } from './CrossLatestSubChain';

export default function SubChain() {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const onSearch = useCallback(() => {
    const id = value.trim();
    if(id){
      setValue(id);
      CrossSearch({
        Value: id,
        ChainId: chainId,
      }).then((res) => {
        if (res.Data?.Type !== undefined) {
          switch (res.Data.Type) {
            case 0: // 跨链详情
              navigate(`/${chainId}/subchain/crossTransactionDetail?crossId=${res.Data.Data}`);
              break;
            case 1: // 子链详情
              navigate(`/${chainId}/subchain/subChainDetail?subChainId=${res.Data.Data}`);
              break;
            default:
              notification.error({
                title: '没有搜索到任何结果',
                description: `搜索： ${value}没有任何结果`,
                unique: true,
                duration: 3000,
              });
              break;
          }
        }
      });
    }
   
  }, [value, chainId]);
  const onKeyPress = useCallback(
    (e) => {
      if (e.which === 13) {
        onSearch();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [onSearch],
  );

  return (
    <>
      <div className={style.top}>
        <div className={style.top_info}>
          <div className={style.top_info_search_input}>
            <input
              value={value}
              onChange={(input) => {
                setValue(input.target.value);
              }}
              onKeyPress={onKeyPress}
              placeholder="请输入跨链id/子链名称搜索"
            />
          </div>
          <div onClick={onSearch} className={style.top_info_search_bt}>
            搜索
          </div>
        </div>
        <div className={style.statistics}>
          <Statistics />
        </div>
      </div>
      <CrossLatestTx />
      <CrossLatestSubChain />
    </>
  );
}
