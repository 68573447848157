import { message, Upload } from 'tea-component';
import style from './index.module.scss';
import React, { useCallback } from 'react';

export default function FileUpload({
  value,
  fileName,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
  accept,
}: {
  value: string;
  fileName: string;
  onChange: (v: string) => void;
  accept?: string[] | string;
}) {
  const accepttypes = Array.isArray(accept) ? accept : accept?.split(',');
  const beforeUpload = useCallback(
    (file: File) => {
      const type: string = file.name.match(/\.\w+$/)?.[0] as string;
      const isaccept = accepttypes ? accepttypes.indexOf(type) > -1 : true;
      if (!isaccept) {
        message.error({
          content: '文件解析错误，请上传正确的文件。',
        });
        onChange('');
        return false;
      }
      const render = new FileReader();
      render.onload = function (e) {
        if (e.target?.result) {
          onChange(e.target.result as string);
        } else {
          message.error({
            content: '文件解析错误，请上传正确的文件。',
          });
        }
      };
      render.readAsText(file);
      return false;
    },
    [onChange],
  );
  return value
    ? (
      <div className={style.upload_c}>
        <div className={style.upload_bt}>
          <div className={style.isupload}>{fileName}已上传</div>
          <div onClick={() => onChange('')} className={style.delete}></div>
        </div>
      </div>
    )
    : (
      <Upload beforeUpload={beforeUpload} accept={accept}>
        <div className={style.upload_c}>
          <div className={style.upload_bt}>
            <div className={style.file}></div>文件上传
          </div>
        </div>
      </Upload>
    );
}
