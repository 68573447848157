import { decode } from 'js-base64';
// 解码不出汉字
export const transformBase64ToStr = (base: string): string => {
  try {
    const decode = atob(base);
    const str = decodeURI(decode);
    return str;
  } catch (e) {
    return base;
  }
};

export const base64Decode = (str: string): string => {
  try {
    const decodeStr = decode(str);
    return decodeStr;
  } catch (e) {
    return str;
  }
};

export const formatNumberToThousands = (num: string | number | undefined | null): string => {
  if (!num) {
    return '0';
  }
  const count = `${num}`;
  const list = count.split('').reverse();
  let res = '';
  const l = list.length - 1;
  list.forEach((item, index) => {
    res = item + res;
    if ((index + 1) % 3 === 0 && l !== index) {
      res = `,${res}`;
    }
  });
  return res;
};
// 截取
export const formatHashDetail = (str: string) => {
  if (str.length <= 43) {
    return str;
  }
  return `${str.slice(0, 14)}...${str.slice(str.length - 26)}`;
};
/** url参数格式化 */
export const formatParams = () => {
  const params = window.location.search
    .slice(1)
    ?.split('&')
    ?.map((item) => item?.split('='))
    ?.reduce((sum: any, cur) => {
      sum[cur?.[0]] = cur?.[1];
      return sum;
    }, {} as any);
  return params;
};

export const emptyToStrikethrough = (str?: string) => (str === undefined || str === '' ? '--' : str);

export const strToJsonArray = <T>(str: string): T[] => {
  if (!str) {
    return [];
  }

  try {
    const json: T[] = JSON.parse(str);
    return Array.isArray(json) ? json : [];
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return [];
  }
};
export const isOlderThanVersion = (currentVersion: string, compareVersion: string) => {
  const COMPAREVERSIONS = compareVersion.split('.');
  const CURRENTVERSIONS = currentVersion.split('.');
  for (let i = 0; i < CURRENTVERSIONS.length; i++) {
    const current = parseInt(CURRENTVERSIONS[i], 10);
    const compare = COMPAREVERSIONS[i] ? parseInt(COMPAREVERSIONS[i]) : 0;
    if (current < compare) {
      return true;
    }
    if (current === compare) {
      continue;
    } else {
      return false;
    }
  };
  if (COMPAREVERSIONS.length > CURRENTVERSIONS.length) {
    return true;
  }
  return false;
};

export const stringToByte = (str: string) => {
  const bytes = [];
  const len = str.length;
  let c;
  for (let i = 0; i < len; i++) {
    c = str.charCodeAt(i);
    if (c >= 0x010000 && c <= 0x10ffff) {
      bytes.push(((c >> 18) & 0x07) | 0xf0);
      bytes.push(((c >> 12) & 0x3f) | 0x80);
      bytes.push(((c >> 6) & 0x3f) | 0x80);
      bytes.push((c & 0x3f) | 0x80);
    } else if (c >= 0x000800 && c <= 0x00ffff) {
      bytes.push(((c >> 12) & 0x0f) | 0xe0);
      bytes.push(((c >> 6) & 0x3f) | 0x80);
      bytes.push((c & 0x3f) | 0x80);
    } else if (c >= 0x000080 && c <= 0x0007ff) {
      bytes.push(((c >> 6) & 0x1f) | 0xc0);
      bytes.push((c & 0x3f) | 0x80);
    } else {
      bytes.push(c & 0xff);
    }
  }
  return bytes;
};

export const byteToHex = (bytes: number[]) => bytes.map((byte) => (byte & 0xff).toString(16)).join('');

export function stringToHex(str: string) {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
}

export const decodeContractSignature = (functionName:string) => {
  const regExp = /\(([^)]+)\)/;
  const parameters:any[] = [];
  const matches = regExp.exec(functionName);
  if (!matches) {
    return false;
  }
  if (matches.length < 2) {
    return false;
  }
  const parameterString = matches[1];
  const parameterStringDelimited = parameterString.split(',');
  parameterStringDelimited.forEach((item, index) => {
    parameters.push({
      type: item.trim(),
      name: `param${index + 1}`
    });
  });
  return parameters;
};
