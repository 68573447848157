import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Home from './Home';
import Chain from './Chain';
import Block from './Block';
import SubChain from './SubChain';
import BlockDetail from './Block/Detail';
import Transaction from './Transaction';
import TransactionDetail from './Transaction/Detail';
import Contract from './Contract';
import ContractDetail from './Contract/Detail';
import ChainAccount from './ChainAccount';
import Node from './Node';
import Origin from './Origin';
import User from './User';
import style from './index.module.scss';
import { Routes, Route, Navigate, useNavigate, Link, useLocation } from 'react-router-dom';
import ChainSelect from './components/ChainSelect';
import ImgFlower from '@imgs/flower.png';
import ImgChainMaker from '@imgs/chainmaker.png';
import TWEEN from '@tweenjs/tween.js';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
import NFTDetail from './Transaction/NFTDetail';
import { GetChainConfig, GetMainCrossConfig } from '@src/utils/apis';
import { CrossTransactionDetail } from './SubChain/transactionDetail';
import { SubChainDetail } from './SubChain/subchainDetail';
import { SubChainList } from './SubChain/subchainList';
import { CrossChainList } from './SubChain/crossChainList';
import DBQueryList from '@src/routes/main/DBQuery';
import DBQueryCreate from '@src/routes/main/DBQuery/detail';
import IdaDataDetail from '@src/routes/main/ida/ida-data-detail';
import { AppContext } from '@components/context';
function animate(time: any) {
  requestAnimationFrame(animate);
  TWEEN.update(time);
}
requestAnimationFrame(animate);

export default function Main() {
  const { appConfig } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const [hasChainSetting, setHasChainSeeting] = useState<boolean>(false);
  const [isShowSubChain, setIsShowSubChain] = useState<boolean>(false);
  // const dispatch = useDispatch();
  // const trustedChainId = useSelector((state: RootReducer) => state.trustedChainIdReducer);
  const updateChainId = useCallback((value) => {
    window.open(`/${value}/home`);
  }, []);
  const onInit = useCallback((value) => {
    setTimeout(() => {
      navigate(`/${value}/home`, { replace: true });
    }, 0);
  }, []);
  /**
   * 获取平台唯一的链Id，防篡改链Id
   */

  useEffect(() => {
    GetChainConfig({}).then((res) => {
      setHasChainSeeting(res.Data);
    });
    // setHasChainSeeting(true);
  }, []);

  useEffect(() => {
    if (chainId) {
      GetMainCrossConfig({ ChainId: chainId }).then((res) => {
        setIsShowSubChain(res.Data.ShowTag);
      });
    }
  }, [chainId]);
  const [showTitle, setShowTitle] = useState(true);

  useEffect(() => {
    console.log(location);
    setShowTitle(location.pathname===`/${chainId}/home`||location.pathname===`/${chainId}/subchain`);

  }, [location.pathname,chainId]);

  return (
    <>
      <div className={style.header}>
        <div className={style.header_c}>
          <Link to={`/${chainId}/home`}>
            <img src={ImgChainMaker} className={style.header_logo} />
          </Link>
          {showTitle &&isShowSubChain && <>
            <Link to={`/${chainId}/home`} className={`${style.header_tab} ${location.pathname===('/'+chainId+'/home')?style.active_tab:''}`}>浏览器</Link>
            <Link to={`/${chainId}/subchain`} className={`${style.header_tab} ${location.pathname===('/'+chainId+'/subchain')?style.active_tab:''}`}>主子链网</Link>
          </>}
          <div className={style.header_setting}>
            <ChainSelect onChange={updateChainId} onInit={onInit} />
            {hasChainSetting && (
              <img
                onClick={() => navigate(`/${chainId}/chain`)}
                src={ImgFlower}
                className={style.header_seeting_icon}
              />
            )}
          </div>
        </div>
      </div>
      {chainId && chainId !== 'v' && (
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="node" element={<Node />} />
          <Route path="chain" element={<Chain />} />
          <Route path="subchain/crossTransactionDetail" element={<CrossTransactionDetail />} />
          <Route path="subchain/subChainDetail" element={<SubChainDetail />} />
          <Route path="subchain/subChainList" element={<SubChainList />} />
          <Route path="subchain/crossChainList" element={<CrossChainList />} />
          <Route path="subchain" element={<SubChain />} />
          <Route path="block" element={<Block />} />
          <Route path="block/:blockHash" element={<BlockDetail />} />
          <Route path="transaction" element={<Transaction />} />
          <Route path="transaction/:txId" element={<TransactionDetail />} />
          <Route path="nft" element={<NFTDetail />} />
          <Route path="chainaccount/*" element={<ChainAccount />} />
          <Route path="contract" element={<Contract />} />
          <Route path="contract/ida/:contractAddr" element={<IdaDataDetail />} />
          <Route path="contract/:contractAddr/*" element={<ContractDetail />} />
          <Route path="origin" element={<Origin />} />
          <Route path="user" element={<User />} />
          {
            appConfig.APP_IS_SHOW_DB_QUERY && <>
              <Route path="dbQuery" element={<DBQueryList/>}/>
              <Route path="dbQuery/new" element={<DBQueryCreate/>}/>
              <Route path="dbQuery/:taskId" element={<DBQueryCreate/>}/>
            </>
          }
          <Route path="*" element={<Navigate to="home" />} />
        </Routes>
      )}
    </>
  );
}
