import { SelectOption } from 'tea-component';

export const AuthTypes = {
  permissionedwithcert: '证书模式',
  public: '公钥模式',
};

const AuthTypeKeys = Object.keys(AuthTypes);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AuthTypeOptions: SelectOption[] = AuthTypeKeys.map((item) => ({
  value: item,
  text: `${(AuthTypes as any)[item]}(${item})`,
}));

export const HashTypes = {
  0: '国密',
  1: '非国密',
};
const HashTypeKeys = Object.keys(HashTypes);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const HashTypeOptions: SelectOption[] = HashTypeKeys.map((item) => ({
  value: item,
  text: `${(HashTypes as any)[item]}(${item})`,
}));
/**
  0 ContractInitStored  已存储
  1 ContractUpgradeStored 升级存储
  2 ContractInitFailure 发布失败
  3 ContractInitOk 发布成功
  4 ContractUpgradeFailure 升级失败
  5 ContractUpgradeOK 升级成功
  6 ContractFreezeFailure 冻结失败
  7 ContractFreezeOK 冻结成功
  8 ContractUnfreezeFailure 解冻失败
  9 ContractUnfreezeOK 解冻成功
  10 ContractRevokeFailure 注销失败
  11 ContractRevokeOK 注销成功
 */
export const ContractStatusMap = {
  0: '已存储',
  1: '已存储',
  2: '已存储',
  3: '已存储',
  4: '已存储',
  5: '已存储',
  6: '已存储',
  7: '已存储',
  8: '已存储',
  9: '已存储',
  10: '已存储',
  11: '已存储',
};

export type ChainStatusVales = 0 | 1 | 2 | 3;
type ChainStatusMap = {
  [k in ChainStatusVales]: {
    text: string;
    theme: 'success' | 'danger' | 'warning';
  };
};
export const chainStatusMap: ChainStatusMap = {
  0: {
    text: '正常',
    theme: 'success',
  },
  1: {
    text: '异常',
    theme: 'danger',
  },
  2: {
    text: '取消',
    theme: 'success',
  },
  3: {
    text: '删除中',
    theme: 'warning',
  },
};
const transfromMapToOptions = (map: any) => {
  const keys = Object.keys(map);
  const options: { text: string; value: string }[] = [];
  keys.forEach((item) => {
    options.push({
      text: `${map[item]}`,
      value: `${item}`,
    });
  });
  return options;
};

export enum EnumContractStatus {
  normal = 0,
  lock = 1,
  logout = 2,
}

export const ContractStatus = {
  [EnumContractStatus.normal]: '正常',
  [EnumContractStatus.lock]: '已冻结',
  [EnumContractStatus.logout]: '已注销',
};

export enum EnumParamEncodeType {
  org = 0,
  utf8 = 1,
  hex = 2,
}

export const ParamEncodeType = {
  [EnumParamEncodeType.org]: 'Original',
  [EnumParamEncodeType.utf8]: 'UTF-8',
  [EnumParamEncodeType.hex]: 'Hex',
};

export const PARAMENCODEOPTIONS = transfromMapToOptions(ParamEncodeType);
