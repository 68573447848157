import { SQLParamType, SQLRunType } from '@src/types';
import { formatTime } from '@utils/tools';
import { SelectOptionWithGroup } from 'tea-component/lib/select/SelectOption';


// 任务名称长度
export const DB_QUERY_TASK_NAME_LENGTH = 50;

/**
 * SQL内容中抽离参数
 */
export const extractParamsByContent = (content: string): string[] => {
  if (!content) {
    return [];
  }
  const pattern = /{{[a-zA-Z0-9-_:]{1,64}}}/g;
  const matches = content.match(pattern);

  if (!matches) {
    return [];
  }
  return Array.from(new Set(matches))
    .map((match) => match.slice(2, -2));
};

/**
 * 提供用户设定SQL参数的类型
 */
export const SQL_PARAM_OPTIONS: SelectOptionWithGroup[] = [
  {
    value: SQLParamType.Text.toString(),
    text: 'Text',
  },
  {
    value: SQLParamType.Number.toString(),
    text: 'Number',
  },
  {
    value: SQLParamType.DateTime.toString(),
    text: 'DateTime',
  },
  {
    value: SQLParamType.List.toString(),
    text: 'List',
    tooltip: '举例：1,2,3',
  },
];


/**
 * 提供用户设定SQL参数的类型
 */
export const SQL_RUN_TYPE_OPTIONS = [
  {
    value: SQLRunType.realtime.toString(),
    text: '实时',
  },
  {
    value: SQLRunType.hourPer.toString(),
    text: '每小时',
  },
  {
    value: SQLRunType.hour6Per.toString(),
    text: '每6小时',
  },
  {
    value: SQLRunType.hour12Per.toString(),
    text: '每12小时',
  },
  {
    value: SQLRunType.hour24Per.toString(),
    text: '每24小时',
  },
];

export const SQL_RUN_TYPE_DESC_MAP = SQL_RUN_TYPE_OPTIONS.reduce<{
  [key:  string]: string;
}>((res, item) => {
  res[item.value] = item.text;
  return res;
}, {});

export function formatQueryParamsValue(item: { name: string; type: SQLParamType; value?: any }) {
  if (item.type === SQLParamType.DateTime) {
    return formatTime(+item.value);
  }
  return item.value.toString();
}



