import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageBox from '@components/ui/PageBox';
import detailstyle from '../detail.module.scss';
import liststyle from '../list.module.scss';
import { GetNFTDetail, GetNFTTransferList } from '@src/utils/apis';
import { Col, Form, Row, Table, TableColumn } from 'tea-component';
import { NFTDetail, NFTTransferItem } from '@src/models';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatUnixTime, emptyToStrikethrough, getAllUrlParams } from '@src/utils/tools';
import { MediaComponent } from '@components/ui/Media';
import style from './index.module.scss';
const { pageable, autotip } = Table.addons;
export default function NFTDetailPage() {
  const { tokenId, contractAddr } = getAllUrlParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const [detail, setDetail] = useState<NFTDetail>();
  const [list, setList] = useState<NFTTransferItem[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const columns = useMemo<TableColumn[]>(() => {
    const list: TableColumn<NFTTransferItem>[] = [
      {
        key: 'Timestamp',
        header: '时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
        width: 180,
      },
      {
        key: 'TxId',
        header: '交易Id',
        align: 'left',
        width: 220,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>{TxId}</Link>,
      },
      {
        key: 'ContractMethod',
        header: '合约方法',
        align: 'left',
        render: ({ ContractMethod }) => ContractMethod || '--',
      },
      {
        key: 'From',
        header: '从',
        align: 'left',
        render: ({ From }) => From || '--',
      },
      {
        key: 'To',
        header: '转到',
        align: 'left',
        render: ({ To }) => To || '--',
      },
    ];
    return list;
  }, []);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetNFTTransferList({
      ChainId: chainId,
      ContractAddr: contractAddr,
      Limit: pageSize,
      Offset: pageIndex - 1,
      TokenId: tokenId
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, pageIndex, pageSize, tokenId]);
  useEffect(() => {
    if (!chainId || !tokenId) {
      return;
    }
    GetNFTDetail({ ChainId: chainId, ContractAddr: contractAddr, TokenId: tokenId }).then((res) => {
      if (res.Data) {
        setDetail(res.Data);
      }
    });
  }, [chainId, tokenId, contractAddr]);
  useEffect(() => {
    getList();
  }, [pageIndex, pageSize]);
  console.log(detail);
  return (
    <PageBox title="NFT详情">
      <div className={detailstyle.detail}>
        <Form.Title>NFT基本信息</Form.Title>
        <Row>
          <Col span={10}>
            {detail?.Metadata?.imageUrl
              ? (
                <div className={style.nft_img}>
                  <MediaComponent fileUrl={detail.Metadata.imageUrl} urlType={detail.Metadata.urlType} />
                </div>
              )
              : (
                <div className={style.nft_img_auto}></div>
              )}
          </Col>
          <Col span={14}>
            <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
              <Form.Item label="ID">
                <Form.Text>{emptyToStrikethrough(`${tokenId || detail?.TokenId}`)}</Form.Text>
              </Form.Item>
              <Form.Item label="当前持有者">
                <Form.Text>{emptyToStrikethrough(detail?.OwnerAddr)}</Form.Text>
              </Form.Item>
              <Form.Item label="生成时间">
                <Form.Text>{formatUnixTime(detail?.Timestamp)}</Form.Text>
              </Form.Item>
              <Form.Item label="作品名称">
                <Form.Text>{emptyToStrikethrough(detail?.Metadata?.name)}</Form.Text>
              </Form.Item>
              <Form.Item label="作者名">
                <Form.Text>{emptyToStrikethrough(detail?.Metadata?.author)}</Form.Text>
              </Form.Item>
              <Form.Item label="作品URL">
                <Form.Text>{emptyToStrikethrough(detail?.Metadata?.imageUrl)}</Form.Text>
              </Form.Item>
              <Form.Item label="作品hash">
                <Form.Text>{emptyToStrikethrough(detail?.Metadata?.seriesHash)}</Form.Text>
              </Form.Item>
              <Form.Item label="作品描述">
                <Form.Text>{emptyToStrikethrough(detail?.Metadata?.description)}</Form.Text>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Form.Title>流转记录</Form.Title>
        <Table
          className={liststyle.table}
          compact={false}
          records={list}
          recordKey="TxId"
          bordered={true}
          disableTextOverflow={true}
          columns={columns}
          addons={[
            autotip({
              isLoading,
            }),
            pageable({
              recordCount,
              pageIndex,
              pageSize,
              onPagingChange: (query) => {
                if (query?.pageIndex) {
                  setPageIndex(query.pageIndex);
                }
                if (query?.pageSize) {
                  setPageSize(query.pageSize);
                }
              },
            }),
          ]}
        />
      </div>
    </PageBox>
  );
}
