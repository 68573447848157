import { useLocation } from 'react-router-dom';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function keyBy<T>(list: T[], key: string) {
  return list.reduce((acc, item: T) => {
    // @ts-ignore
    acc[item[key]] = item;
    return acc;
  }, {} as { [key: string]: T });
}

export function joinBy(splitter: string, ...list: string[]) {
  return list.filter(item => item).join(splitter);
}

export function distinct<T>(arr: T[]) {
  return Array.from(new Set(arr));
}

export const jsonUtils = {
  parse: (str: string) => {
    try {
      return JSON.parse(str);
    } catch {
      return str;
    }
  },
};
