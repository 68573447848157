import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { RootReducer } from '@src/store';

/**
 * 获取当前链的chainId
 */
export function useCurrentChainId() {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId ?? '', [currentChain]);
  return chainId;
}
