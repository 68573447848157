import { Nullable } from '@src/types/index';

export interface DBResponseData<T> {
  code: number,
  msg: string;
  data: T;
}

export interface GetDBTableListParam {
  chainId?: string;
}

export interface GetDBTableListResponse {
  chainId: string;
  database: string;
  tables: {
    tableName: string; // 表名称
    tableDescribe: string; // 表描述名称
    fullName: string; // 带数据库的表全称
    columns: {
      name: string;
      type: string;
      describe: string;
    }[];
    indexs: {
      name: string;
      type: string;
      value: string;
    }[];
  }[];
}

export interface GetRegularQueryTaskListParam {
  pageIndex: number;
  pageSize: number;
  chainId: string;
  show: 'all' | 'public' | 'persona';
  title: string;
  userAddr: string;
}


export interface GetDatabaseListResponse {
  dbType: 'Mysql' | 'ClickHouse' | 'Pgsql';
  dbList: {
    chainId: string; // 链名称，用于列表展示名称
    database: string; // 数据库名称
    tables: GetDBTableListResponse['tables']; // 前端拼接树结构
  }[];
}

export interface GetRegularQueryTaskListResponse {
  total: number;
  list: {
    title: string;
    userId: string;
    userAddr: string; // 账户地址
    queryId: string;
    isPublic: boolean;
    isOwner: boolean;
    runType: SQLRunType;
    dataUpdateTime: number;// 数据更新时间，单位秒
    taskUpdateTime: number;// 任务更新时间，单位秒
  }[];
}

export enum SQLParamType {
  Text = 0,
  Number = 1,
  DateTime = 2,
  List = 3
}

export interface SaveQuerySqlParam {
  queryId: number; // 0表示新增
  querySql: string; // sql语句，进行base64编码
  queryParams: {
    name: string;
    type: SQLParamType;
    value?: any;
  }[];
}

export enum SQLRunType {
  realtime = 0,
  hourPer = 1,
  hour6Per = 2,
  hour12Per = 3,
  hour24Per = 4,
}

export interface SaveRegularQueryTaskParam {
  queryId: number;
  chainId: string;
  title: string;
  isPublic: boolean;
  runType: SQLRunType;
  params: {
    name: string;
    value: string;
  }[];
}

export type SaveTaskParams =
  Pick<SaveRegularQueryTaskParam, 'title' | 'runType' | 'isPublic' | 'chainId'>
  & {
    queryId: Nullable<SaveQuerySqlParam['queryId']>;
    isOwner: boolean;
  };
export enum ExecutionStatus {
  ing = 0,
  success = 1,
  fail = 2,
}

export type ExecutionResultResponse = {
  queryId: number;
  status: ExecutionStatus; // 0:进行中，1:成功，2：失败
  timestamp: number;
  runtime: number;
  succeeded: {
    columns: string[];
    result: any[];
  };
  failed: {
    type: number, //失败类型：1:超时，2:报错，3:取消查询
    message: string
  }
};
