import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Form, Input, message, Modal, Select, Switch } from 'tea-component';
import { Controller, useForm } from 'react-hook-form';
import { SaveRegularQueryTask } from '@utils/apis';
import { SaveTaskParams, SQLParamType } from '@src/types';
import { getStatus } from '@utils/form';
import { DB_QUERY_TASK_NAME_LENGTH, SQL_RUN_TYPE_OPTIONS } from '@src/routes/main/DBQuery/utils';
import { useNavigate } from 'react-router-dom';

/**
 * 保存SQL任务
 */
function SaveTaskModalContainer({
  queryParams,
  runTaskParams,
}: {
  runTaskParams: SaveTaskParams;
  queryParams: {
    name: string;
    type: SQLParamType;
    value?: any;
  }[],
}, ref: any) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const {
    control,
    getValues,
    reset,
    formState: { isValid, isValidating, isSubmitted },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: runTaskParams.title,
      isPublic: runTaskParams.isPublic,
      runType: runTaskParams.runType.toString(),
    },
  });
  const onOk = useCallback(async () => {
    const values = getValues();
    await SaveRegularQueryTask({
      chainId: runTaskParams.chainId,
      queryId: runTaskParams.queryId as number,
      runType: +values.runType,
      isPublic: values.isPublic,
      title: values.title.trim(),
      params: queryParams.map(item => ({
        name: item.name,
        value: item.value.toString(),
      })),
    });
    setVisible(false);
    message.success({
      content: '保存成功',
      duration: 1500,
      onClose: () => {
        navigate(`/${runTaskParams.chainId}/dbQuery`);
      },
    });
  }, [getValues, runTaskParams.chainId, runTaskParams.queryId, queryParams, navigate]);

  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true);
    },
  }), []);


  useEffect(() => {
    reset({
      runType: runTaskParams.runType.toString(),
      isPublic: runTaskParams.isPublic,
      title: runTaskParams.title,
    });
  }, [reset, runTaskParams]);

  return <>
    <Modal visible={visible} caption="保存查询结果" onClose={onClose} className={'explorer-modal'}>
      <Modal.Body>
        <Form fixedLabelWidth={110} layout="fixed">
          <Controller control={control} rules={{
            validate: (value) => {
              if (!value.trim()) {
                return '标题不能为空';
              }
              if (value.length > DB_QUERY_TASK_NAME_LENGTH) {
                return `标题长度不能超过${DB_QUERY_TASK_NAME_LENGTH}个字符`;
              }
            },
          }}
          render={({ field, fieldState }) => {
            return <Form.Item label="标题" required message={fieldState.error?.message}
              status={getStatus({
                fieldState,
                isValidating,
                isSubmitted,
              })}><Input
                size={'full'}
                placeholder={`请输入标题(${DB_QUERY_TASK_NAME_LENGTH}个字符以内)`}
                {...field}/></Form.Item>;
          }} name={'title'}/>

          <Form.Item label="是否公开">
            <Controller control={control}
              render={({ field }) => {
                return <Switch {...field}/>;
              }} name={'isPublic'}/>
          </Form.Item>
          <Form.Item label="定时执行">
            <Controller control={control}
              render={({ field }) => {
                return <Select options={SQL_RUN_TYPE_OPTIONS} {...field}/>;
              }}
              name={'runType'}/>
          </Form.Item>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" onClick={onOk} disabled={!isValid}>
          确定
        </Button>
        <Button type="weak" onClick={onClose}>
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  </>;
}

export const SaveTaskModal = React.forwardRef(SaveTaskModalContainer);
