import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../../list.module.scss';
import { Table, TableColumn } from 'tea-component';
import { TransferItem } from '@src/models';
import { GetFTPositionList } from '@src/utils/apis';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const { pageable } = Table.addons;

export default function Transfer({ name, type }: { name?: string, type?:string; }) {
  const { contractAddr } = useParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [list, setList] = useState<TransferItem[]>([]);
  const getList = useCallback(() => {
    if (!name) {
      return;
    }
    GetFTPositionList({
      ChainId: chainId || '',
      Offset: pageIndex - 1,
      ContractAddr: contractAddr || '',
      Limit: pageSize,
      OwnerAddr: name,
    }).then((res: any) => {
      if (res && res?.GroupList?.length > 0) {
        setList(res.GroupList);
        setRecordCount(res.TotalCount);
      }
    });
  }, [chainId, name, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [name, chainId, pageIndex, pageSize]);
  const columns = useMemo<TableColumn[]>(() => {
    const list: TableColumn<TransferItem>[] = [
      {
        key: 'ContractSymbol',
        header: 'Token',
        align: 'left',
        width: 380,
      },
      {
        key: 'ContractName',
        header: '合约名称',
        align: 'left',
      },
      {
        key: 'ContractType',
        header: '合约类型',
        align: 'left',
      },
      {
        key: 'Amount',
        header: '持有数量',
        align: 'left',
      },
    ];
    return list;
  }, [name, type]);

  return (
    <Table
      className={liststyle.table}
      compact={false}
      records={list}
      recordKey="TxId"
      bordered={true}
      disableTextOverflow={true}
      columns={columns}
      addons={[
        pageable({
          recordCount,
          onPagingChange: (query) => {
            if (query?.pageIndex) {
              setPageIndex(query.pageIndex);
            }
            if (query?.pageSize) {
              setPageSize(query.pageSize);
            }
          },
        }),
      ]}
    />
  );
}
