export type TrustedChainIdState = string;

/**
 * 存储当前受信任的chainId，防止篡改url
 * @param state 是否信任状态
 * @param action 对方开放动作
 * @returns string 受信任的chainId
 */
export const trustedChainIdReducer = (
  state = '',
  action: {
    type: string;
    payload?: string;
  },
): string => {
  let newstate = state;
  if (action.type === 'UPDATE_ISTRUST') {
    if (action.payload) {
      newstate = action.payload;
    }
  }
  return newstate;
};
