import React, { useState, useEffect } from 'react';
import style from './index.module.scss';
import { CrossLatestSubChainList } from '@src/utils/apis';
import { useNavigate } from 'react-router-dom';
import { Table } from 'tea-component';
import { CrossLatestSubChainListItem } from '@src/models';
import { useParams } from 'react-router-dom';
import { formatTime } from '@src/utils/tools';
import { SubChainStatusMap } from '@src/constant';

export function CrossLatestSubChain() {
  const { chainId } = useParams();
  const navigate = useNavigate();

  const [crossLatestSubChainList, setCrossLatestSubChainList] = useState<CrossLatestSubChainListItem[]>([]);

  useEffect(() => {
    if (chainId) {
      CrossLatestSubChainList({ ChainId: chainId }).then((res) => {
        setCrossLatestSubChainList(res.GroupList);
      });
    }
  }, [chainId]);

  return (
    <>
      <h3>子链列表 <b onClick={() => navigate(`/${chainId}/subchain/subChainList`)}>更多{'>>'}</b></h3>
      <div className={style.news}>
        <Table
          className={style.detail_table}
          compact={false}
          records={crossLatestSubChainList}
          recordKey="SubChainId"
          bordered={true}
          disableTextOverflow={true}
          columns={[
            {
              key: 'SubChainName',
              header: '子链名称',
              align: 'left',
              render: (record: CrossLatestSubChainListItem) => {
                return <a href={`/${chainId}/subchain/subChainDetail?subChainId=${record.SubChainId}`}>{record.SubChainName}</a>;
              },
            },
            {
              key: 'SubChainId',
              header: 'SubchainID',
              align: 'left',
              render: (record: CrossLatestSubChainListItem) => {
                return <a href={`/${chainId}/subchain/subChainDetail?subChainId=${record.SubChainId}`}>{record.SubChainId}</a>;
              }
            },
            {
              key: 'BlockHeight',
              header: '区块高度',
              align: 'left',
            },
            {
              key: 'CrossTxNum',
              header: '累计跨链交易数',
              align: 'left',
            },
            {
              key: 'CrossContractNum',
              header: '累计跨链合约数',
              align: 'left',
            },
            {
              key: 'Status',
              header: '运行状态',
              align: 'left',
              width: 168,
              render: (record: CrossLatestSubChainListItem) => {
                return SubChainStatusMap?.[record.Status]?.text;
              }
            },
            {
              key: 'Timestamp',
              header: '跨链发起时间',
              align: 'left',
              width: 193,
              render: (record: CrossLatestSubChainListItem) => {
                return formatTime(record.Timestamp * 1000);
              },
            },
          ]}
        />
      </div>
    </>
  );
}
