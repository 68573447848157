
import React, { useEffect, useState } from 'react';
import { Form, } from 'tea-component';
import detailstyle from '../detail.module.scss';
import PageBox from '../../../components/ui/PageBox';
import { CrossSubChainDetailInfo } from '@src/models';
import { CrossSubChainDetail } from '@src/utils/apis';
import { useParams } from 'react-router-dom';
import { getAllUrlParams } from '@src/utils/tools';
import { SubChainTypeMap, SubChainStatusMap } from '@src/constant';
import { CrossChainRank } from './crossChainRank';
import { CrossChainTable } from './crossChainList';
import { IconTitle } from './IconTitle';

export function SubChainDetail() {
  const { chainId } = useParams();
  const { subChainId } = getAllUrlParams();
  const [crossSubChainDetailInfo, setCrossSubChainDetailInfo] =
    useState<CrossSubChainDetailInfo | null>(null);

  useEffect(() => {
    CrossSubChainDetail({ ChainId: chainId, SubChainId: subChainId }).then(
      (res) => {
        setCrossSubChainDetailInfo(res.Data);
      }
    );
  }, [chainId, subChainId]);

  // 装饰条
  const decorationStrip = (title: string) => {
    return <div><div className={`${detailstyle['decoration-left']} ${detailstyle.decoration}`}></div>{title}<div className={`${detailstyle['decoration-right']} ${detailstyle.decoration}`}></div></div>;
  };

  return (
    <PageBox title="子链详情">
      <div className={detailstyle.detail}>
        <Form.Title>{IconTitle('子链基本信息')}</Form.Title>
        <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
          <Form.Item label="子链名称">
            <Form.Text>{crossSubChainDetailInfo?.SubChainName}</Form.Text>
          </Form.Item>
          <Form.Item label="最新区块高度">
            <Form.Text>{crossSubChainDetailInfo?.BlockHeight}</Form.Text>
          </Form.Item>
          <Form.Item label="区块链架构">
            <Form.Text>
              {SubChainTypeMap[crossSubChainDetailInfo?.ChainType ?? ""]?.text}
            </Form.Text>
          </Form.Item>
          <Form.Item label="SubchainID">
            <Form.Text>{crossSubChainDetailInfo?.SubChainId}</Form.Text>
          </Form.Item>
        </Form>
        <Form.Title>{IconTitle('子链运行信息')}</Form.Title>
        <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
          <Form.Item label="累计跨链交易数">
            <Form.Text>{crossSubChainDetailInfo?.CrossTxNum}</Form.Text>
          </Form.Item>
          <Form.Item label="累计跨链合约数">
            <Form.Text>{crossSubChainDetailInfo?.CrossContractNum}</Form.Text>
          </Form.Item>
          <Form.Item label="运行状态">
            <Form.Text>
              {SubChainStatusMap[crossSubChainDetailInfo?.Status ?? ""]?.text}
            </Form.Text>
          </Form.Item>
        </Form>
        <Form.Title>{IconTitle('子链网关信息')}</Form.Title>
        <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
          <Form.Item label="跨链网关名称">
            <Form.Text>{crossSubChainDetailInfo?.GatewayName}</Form.Text>
          </Form.Item>
          <Form.Item label="跨链网关ID">
            <Form.Text>{crossSubChainDetailInfo?.GatewayId}</Form.Text>
          </Form.Item>
        </Form>
        <Form.Title>{IconTitle('跨链历史')}</Form.Title>
        <CrossChainTable SubChainId={subChainId} />
        <Form.Title>
          {decorationStrip('跨链数据排行')}
        </Form.Title>
        <CrossChainRank />
      </div>
    </PageBox>
  );
}
