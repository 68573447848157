import * as monaco from 'monaco-editor';
import sqlFormatter from '@sqltools/formatter';

import { language } from 'monaco-editor/esm/vs/basic-languages/sql/sql.js';


monaco.languages.registerDocumentFormattingEditProvider('sql', {
  provideDocumentFormattingEdits(model) {
    let formatted = sqlFormatter.format(model.getValue(), {
      indent: ' ',
    });
    formatted = formatted.replace(/{\s+{\s+/g, '{{').replace(/\s+}\s+}/g, '}}');
    return [
      {
        range: model.getFullModelRange(),
        text: formatted,
      },
    ];
  },
});
monaco.languages.registerDocumentRangeFormattingEditProvider('sql', {
  provideDocumentRangeFormattingEdits(model, range) {
    let formatted = sqlFormatter.format(model.getValue(), {
      indent: ' ',
    });
    formatted = formatted.replace(/{\s+{\s+/g, '{{').replace(/\s+}\s+}/g, '}}');
    return [
      {
        range: range,
        text: formatted,
      },
    ];
  },
});

const sqlKeywords: string[] = language.keywords;

// SQL关键字
const sqlKeywordsSuggest = sqlKeywords.map((key) => ({
  label: key,
  kind: monaco.languages.CompletionItemKind.Keyword,
  insertText: key,
  detail: 'keyword',
}));

/**
 * 注册SQL语言补全
 * @return 销毁函数
 */
export function registerCompletionForSQL({ databases, tables, columns }: {
  databases: string[];
  tables: string[];
  columns: string[];
}) {

  // 数据库补全
  const databaseSuggest = databases.map((key: string) => ({
    label: key,
    kind: monaco.languages.CompletionItemKind.Enum,
    insertText: key,
    detail: 'database',
  }));

  // 表补全
  const tableSuggest = tables.map((key: string) => ({
    label: key,
    kind: monaco.languages.CompletionItemKind.Constant,
    insertText: key,
    detail: 'table',
  }));

  // 字段补全
  const columnSuggest = columns.map((key: string) => ({
    label: key,
    kind: monaco.languages.CompletionItemKind.Constant,
    insertText: key,
    detail: 'column',
  }));

  return monaco.languages.registerCompletionItemProvider('sql', {
    triggerCharacters: ['.', ' '],
    provideCompletionItems: function (model, position) {
      let suggestions: any[] = [];
      const { lineNumber, column } = position;
      const textBeforePointer = model.getValueInRange({
        startLineNumber: lineNumber,
        startColumn: 0,
        endLineNumber: lineNumber,
        endColumn: column,
      });
      const tokens = textBeforePointer.toLocaleLowerCase().trim().split(/\s+/);
      const lastToken = tokens[tokens.length - 1]; // 获取最后一段非空字符串
      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };

      console.log('databases', databases, 'lastToken', lastToken, 'textBeforePointer', textBeforePointer);

      if (lastToken.endsWith('.')) {
        suggestions = [...tableSuggest];
      } else if (textBeforePointer.endsWith(' ')) {
        if (textBeforePointer.match(/from\s+$/i)) {
          suggestions = [...databaseSuggest];
        } else if (textBeforePointer.match(/where\s+$/i)) {
          suggestions = [...columnSuggest];
        } else {
          suggestions = [...sqlKeywordsSuggest];
        }
      } else {
        suggestions = [...sqlKeywordsSuggest];
      }
      console.log('suggestions', suggestions);
      return {
        suggestions: suggestions.map(item => ({
          ...item,
          range,
        })),
      };
    },
  });
}
