import React, { useEffect, useState } from 'react';
import PageBox from '@components/ui/PageBox';
import { Form, Table, TableColumn } from 'tea-component';
import { GetIDADataDetail } from '@utils/apis';
import { FormItemRender } from '@components/form/form';
import { useParams } from 'react-router';
import { useCurrentChainId } from '@utils/hooks';
import { useSearchParams } from 'react-router-dom';
import {
  IdaAssetCategoryMap,
  IdaAssetImmediatelySupplyMap,
  IdaAssetIsDeletedMap,
  IdaAssetUpdateCycleTypeMap,
} from '@src/routes/main/ida/constant';
import { formatUnixTime } from '@utils/tools';
import { renderBoolean } from '@components/common';
import detailstyle from '@src/routes/main/detail.module.scss';
import { IDADataDetailResponse } from '@src/types';
import { scrollable } from 'tea-component/es/table/addons';
import { jsonUtils } from '@utils/utils';

function DataAssetTable({ data }: {
  data: IDADataDetailResponse['DataAsset'];
}) {
  const columns: TableColumn[] = [
    {
      header: '字段名称',
      key: 'Name',
    },
    {
      header: '字段类型',
      key: 'Type',
    },
    {
      header: '字段长度',
      key: 'Length',
    },
    {
      header: '是否主键',
      key: 'IsPrimaryKey',
      render: ({ IsPrimaryKey }) => renderBoolean(IsPrimaryKey),
    },
    {
      header: '是否非空',
      key: 'IsNotNull',
      render: ({ IsNotNull }) => renderBoolean(IsNotNull),
    },
    {
      header: '是否隐私查询',
      key: 'PrivacyQuery',
      render: ({ PrivacyQuery }) => renderBoolean(PrivacyQuery),
    },
  ];

  if (!data?.length) {
    return null;
  }

  return <Table bordered columns={columns} records={data} addons={[
    scrollable({
      maxHeight: 400,
    }),
  ]}/>;
}

const HEADER_COLUMNS: TableColumn<{
  field: string;
  type: string;
  desc: string;
}>[] = [
  {
    key: 'field',
    header: 'Field',
    width: 300,
  },
  {
    key: 'type',
    header: 'Type',
    width: 150,
  },
  {
    key: 'desc',
    header: 'Desc',
    render: ({ desc }) => <div>
      {desc}
    </div>,
  },
];

function APIAssetTable({ data }: {
  data: IDADataDetailResponse['ApiAsset'];
}) {
  if (!data?.length) {
    return null;
  }
  return <>
    {
      data.map((item, index) => {
        const headerParams = jsonUtils.parse(item.Header);
        return <Form key={index}>
          <FormItemRender title={'Header信息'} value={item.Header}>
            {
              Array.isArray(headerParams)
                ? <Table columns={HEADER_COLUMNS} records={headerParams}
                  bordered={true}
                  compact={false}
                />
                : null
            }
          </FormItemRender>
          <FormItemRender title={'入参信息'} value={item.Params}/>
          <FormItemRender title={'出参信息'} value={item.Response}/>
          <FormItemRender title={'请求类型'} value={item.Method}/>
          <FormItemRender title={'格式类型'} value={item.ResponseType}/>
          <FormItemRender title={'URL地址'} value={item.Url}/>
        </Form>;
      })
    }
  </>;
}


export default function IdaDataDetail() {
  const { contractAddr } = useParams();
  const [searchParams] = useSearchParams();
  const chainId = useCurrentChainId();
  const [detail, setDetail] = useState<IDADataDetailResponse>();

  useEffect(() => {
    GetIDADataDetail({
      ChainId: chainId,
      AssetCode: searchParams.get('assetCode') as string,
      ContractAddr: contractAddr as string,
    }).then((res) => {
      setDetail(res.Data);
    });
  }, [chainId, contractAddr, searchParams]);

  return (
    <PageBox title="数据资产登记详情">
      {
        detail && <div className={detailstyle.detail}>
          <Form.Title>数据产品基本信息</Form.Title>
          <Form hideLabel={false} fixedLabelWidth={150} layout="fixed">
            <FormItemRender title={'产品名称'} value={detail.AssetName}/>
            <FormItemRender title={'产品英文简称'} value={detail.AssetEnName}/>
            <FormItemRender title={'资产类型'}
              value={IdaAssetCategoryMap[detail.Category]}/>
            <FormItemRender title={'数据状态'} value={IdaAssetIsDeletedMap.get(detail.IsDeleted)}/>
            <FormItemRender title={'供应方式'} value={IdaAssetImmediatelySupplyMap.get(detail.ImmediatelySupply)}/>
            <FormItemRender title={'数据规模'} value={detail.DataScale}/>
            <FormItemRender title={'所属行业'} value={detail.IndustryTitle}/>
            <FormItemRender title={'数据产品介绍'} value={detail.Summary}/>
            <FormItemRender title={'上传材料'} value={detail.AnnexUrls.map((item, idx) => {
              return <a key={idx} href={item} className={'display-block'} target={'_blank'} rel="noreferrer">{item}</a>;
            })}/>
            <FormItemRender title={'上链时间'} value={formatUnixTime(detail.CreatedTime)}/>
            <FormItemRender title={'更新时间'} value={formatUnixTime(detail.UpdatedTime)}/>
          </Form>
          <Form.Title>数据产品使用条件
          </Form.Title>
          <Form hideLabel={false} fixedLabelWidth={150} layout="fixed">
            <FormItemRender title={'使用对象'} value={detail.UserCategories}/>
            <FormItemRender title={'更新周期'} value={IdaAssetUpdateCycleTypeMap[detail.UpdateCycleType]}/>
            <FormItemRender title={'时间跨度'} value={detail.TimeSpan}/>
          </Form>
          <Form.Title>数据产品内容
          </Form.Title>
          <DataAssetTable data={detail.DataAsset}/>
          <APIAssetTable data={detail.ApiAsset}/>
        </div>}
    </PageBox>
  );
}
