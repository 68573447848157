import { createGetChannel, createPostChannel } from './request';
import {
  AccountDetail,
  BlockInfo,
  BlockItem,
  CancelSubscribeParam,
  ChainInfo,
  ChainItem,
  ChainListParam,
  ContractInfo,
  ContractItem,
  CrossLatestSubChainListItem,
  CrossLatestTxListItem,
  CrossOverviewDataInfo,
  CrossSearchInfo,
  CrossSearchParam,
  CrossSubChainDetailInfo,
  CrossSubChainListItem,
  CrossSubChainListParam,
  CrossTxDetailInfo,
  CrossTxListItem,
  DeleteSubscribeParam,
  EventItem,
  FTContractDetail,
  FTContractItem,
  FTPositionItem,
  FTTransferItem,
  GetAccountDetailParam,
  GetBlockDetailParam,
  GetBlockListParam,
  GetContractCodeParam,
  GetContractCodeRes,
  GetContractListParam,
  GetContractVersionListItem,
  GetContractVersionListParam,
  GetCrossTxListParam,
  GetDecimalParam,
  GetEventListParam,
  GetFTContractDetailParam,
  GetFTContractListParam,
  GetFTPositionListParam,
  GetFTTransferListParam,
  GetLatestBlockListItem,
  GetLatestBlockListParam,
  GetLatestContractListItem,
  GetLatestContractListParam,
  GetLatestTxListItem,
  GetLatestTxListParam,
  GetNFTContractDetailParam,
  GetNFTContractListParam,
  GetNFTDetailParam,
  GetNFTListParam,
  GetNFTPositionListParam,
  GetNFTTransferListParam,
  GetNodeListParam,
  GetOrgListParam,
  GetTxListParam,
  GetTxNumByTimeItem,
  GetTxNumByTimeParam,
  GetUserListParam,
  NFTContractDetail,
  NFTContractItem,
  NFTDetail,
  NFTItem,
  NFTPositionItem,
  NFTTransferItem,
  NodeItem,
  OriginItem,
  ParamWithChainId,
  ParamWithCrossId,
  ParamWithSubChainId,
  SearchInfo,
  SearchParam,
  SubChainCrossChainListItem,
  Subscribe,
  Tx,
  TxInfo,
  UserItem,
} from '@src/models';
import { Fetch, ResponseData, ResponseList } from './type';
import { GetTransferListParam, TransferItem } from '@src/models/transfer';
import {
  DBResponseData,
  ExecutionResultResponse,
  GetDatabaseListResponse,
  GetDBTableListParam,
  GetDBTableListResponse,
  GetRegularQueryTaskListParam,
  GetRegularQueryTaskListResponse,
  IDADataDetailResponse,
  SaveQuerySqlParam,
  SaveRegularQueryTaskParam,
  SQLRunType,
} from '@src/types';
import axios, { AxiosResponse } from 'axios';
// import { ChainListParam } from '@src/models';

// 链配置是否显示
export const GetChainConfig: Fetch<{}, ResponseData<boolean>> = createGetChannel('GetChainConfig');
// 查看链列表
export const GetChainList: Fetch<ChainListParam, ResponseList<ChainItem>> = createGetChannel('GetChainList');
// 首页查询
export const Search: Fetch<SearchParam, ResponseData<SearchInfo>> = createGetChannel('Search');

// 首页数据统计
export const Decimal: Fetch<GetDecimalParam, ResponseData<ChainInfo>> = createGetChannel('GetOverviewData');

// 按时间段查询交易量
export const GetTxNumByTime: Fetch<GetTxNumByTimeParam, ResponseList<GetTxNumByTimeItem>> = createGetChannel(
  'GetTxNumByTime',
);

// 交易统计曲线
// export const GetTransactionNumByTime: Fetch<
//   GetTransactionNumByTimeParam,
//   ResponseList<TransactionNumByTime>
// > = createGetChannel('GetTransactionNumByTime');

// 组织列表
export const GetOrgList: Fetch<GetOrgListParam, ResponseList<OriginItem>> = createGetChannel('GetOrgList');

// 获取链上用户列表
export const GetUserList: Fetch<GetUserListParam, ResponseList<UserItem>> = createGetChannel('GetUserList');

// 节点列表
export const GetNodeList: Fetch<GetNodeListParam, ResponseList<NodeItem>> = createGetChannel('GetNodeList');

// 查看区块列表
export const GetBlockList: Fetch<GetBlockListParam, ResponseList<BlockItem>> = createGetChannel('GetBlockList');

export const GetLatestBlockList: Fetch<GetLatestBlockListParam, ResponseList<GetLatestBlockListItem>> = createGetChannel('GetLatestBlockList');

// 查看交易列表
export const GetTxList: Fetch<GetTxListParam, ResponseList<Tx>> = createGetChannel('GetTxList');

export const GetLatestTxList: Fetch<GetLatestTxListParam, ResponseList<GetLatestTxListItem>> = createGetChannel(
  'GetLatestTxList',
);

// 最新交易列表
export const GetLatestContractList: Fetch<
GetLatestContractListParam,
ResponseList<GetLatestContractListItem>
> = createGetChannel('GetLatestContractList');
// 合约列表
export const GetContractList: Fetch<GetContractListParam, ResponseList<ContractItem>> = createGetChannel(
  'GetContractList',
);

//  查询区块详情
export const GetBlockDetail: Fetch<GetBlockDetailParam, ResponseData<BlockInfo>> = createGetChannel('GetBlockDetail');

// 查询交易详情

export const GetTxDetail: Fetch<any, ResponseData<TxInfo>> = createGetChannel('GetTxDetail');
// 合约详情
export const GetContractDetail: Fetch<{
  ChainId: string;
  ContractKey: string
}, ResponseData<ContractInfo>> = createGetChannel('GetContractDetail');
// 合约源码
export const GetContractCode: Fetch<GetContractCodeParam, ResponseData<GetContractCodeRes>> = createGetChannel(
  'GetContractCode',
);
// 获取合约版本交易列表
export const GetContractVersionList: Fetch<
GetContractVersionListParam,
ResponseList<GetContractVersionListItem>
> = createGetChannel('GetContractVersionList');
// 获取合约事件列表
export const GetEventList: Fetch<GetEventListParam, ResponseList<EventItem>> = createGetChannel('GetEventList');
// 订阅链（绑定链）
export const SubscribeChain: Fetch<Subscribe, ResponseData<ChainItem>> = createPostChannel('SubscribeChain');
// 取消订阅信息
export const CancelSubscribe: Fetch<CancelSubscribeParam, ResponseData<ChainItem>> = createPostChannel(
  'CancelSubscribe',
);
// 修改订阅信息
export const ModifySubscribe: Fetch<Subscribe, ResponseData<ChainItem>> = createPostChannel('ModifySubscribe');
// 链删除
export const DeleteSubscribe: Fetch<DeleteSubscribeParam, ResponseData<string>> = createPostChannel('DeleteSubscribe');

// 查询流转记录列表 api 已废弃 2024-01-22
export const GetTransferList: Fetch<GetTransferListParam, ResponseList<TransferItem>> = createGetChannel(
  'GetTransferList',
);
export const GetNFTDetail: Fetch<GetNFTDetailParam, ResponseData<NFTDetail>> = createGetChannel('GetNFTDetail');

// 获取NFT合约列表(合约列表页的NFT类)
export const GetNFTContractList: Fetch<GetNFTContractListParam, ResponseList<NFTContractItem>> = createGetChannel('GetNFTContractList');

// 获取NFT列表
export const GetNFTList: Fetch<GetNFTListParam, ResponseList<NFTItem>> = createGetChannel('GetNFTList');

// 获取FT合约列表(合约列表页的Token类)
export const GetFTContractList: Fetch<GetFTContractListParam, ResponseList<FTContractItem>> = createGetChannel('GetFTContractList');

// 获取FT合约持仓列表
export const GetFTPositionList: Fetch<GetFTPositionListParam, ResponseList<FTPositionItem>> = createGetChannel('GetFTPositionList');

// 获取NFT合约持仓列表
export const GetNFTPositionList: Fetch<GetNFTPositionListParam, ResponseList<NFTPositionItem>> = createGetChannel('GetNFTPositionList');

// 获取FT合约详情
export const GetFTContractDetail: Fetch<GetFTContractDetailParam, ResponseData<FTContractDetail>> = createGetChannel('GetFTContractDetail');

// 获取NFT合约详情
export const GetNFTContractDetail: Fetch<GetNFTContractDetailParam, ResponseData<NFTContractDetail>> = createGetChannel('GetNFTContractDetail');

// 获取FT流转列表
export const GetFTTransferList: Fetch<GetFTTransferListParam, ResponseList<FTTransferItem>> = createGetChannel('GetFTTransferList');

// 获取FT流转列表
export const GetNFTTransferList: Fetch<GetNFTTransferListParam, ResponseList<NFTTransferItem>> = createGetChannel('GetNFTTransferList');

// 获取链账户详情
export const GetAccountDetail: Fetch<GetAccountDetailParam, ResponseData<AccountDetail>> = createGetChannel('GetAccountDetail');

// 主子链网配置。根据链配置确定是否展示主子链tag标签
export const GetMainCrossConfig: Fetch<ParamWithChainId, ResponseData<{
  ShowTag: boolean
}>> = createGetChannel('GetMainCrossConfig');

// 首页搜索接口，返回数据是否存在，并返回type对应的查询结果
export const CrossSearch: Fetch<CrossSearchParam, ResponseData<CrossSearchInfo>> = createGetChannel('CrossSearch');

// 首页详情数据
export const CrossOverviewData: Fetch<ParamWithChainId, ResponseData<CrossOverviewDataInfo>> = createGetChannel('CrossOverviewData');

// 获取最新跨链交易列表
export const CrossLatestTxList: Fetch<ParamWithChainId, ResponseList<CrossLatestTxListItem>> = createGetChannel('CrossLatestTxList');

// 获取最新子链列表
export const CrossLatestSubChainList: Fetch<ParamWithChainId, ResponseList<CrossLatestSubChainListItem>> = createGetChannel('CrossLatestSubChainList');

// 获取子链列表
export const CrossSubChainList: Fetch<CrossSubChainListParam, ResponseList<CrossSubChainListItem>> = createGetChannel('CrossSubChainList');

// 获取子链详情
export const CrossSubChainDetail: Fetch<ParamWithSubChainId, ResponseData<CrossSubChainDetailInfo>> = createGetChannel('CrossSubChainDetail');

// 获取跨链交易详情
export const GetCrossTxDetail: Fetch<ParamWithCrossId, ResponseData<CrossTxDetailInfo>> = createGetChannel('GetCrossTxDetail');

// 获取子链历史跨链列表
export const SubChainCrossChainList: Fetch<ParamWithSubChainId, ResponseList<SubChainCrossChainListItem>> = createGetChannel('SubChainCrossChainList');

// 获取跨链交易列表
export const GetCrossTxList: Fetch<GetCrossTxListParam, ResponseList<CrossTxListItem>> = createGetChannel('GetCrossTxList');

export const GetDatabaseList: Fetch<{}, DBResponseData<GetDatabaseListResponse>> = createGetChannel('GetDatabaseList', 'db-query');

// 获取数据库表列表
export const GetDBTableList: Fetch<GetDBTableListParam, DBResponseData<GetDBTableListResponse>> = createGetChannel('GetDBTableList', 'db-query');

// 获取定时任务列表
export const GetRegularQueryTaskList: Fetch<GetRegularQueryTaskListParam, DBResponseData<GetRegularQueryTaskListResponse>> = createGetChannel('GetRegularQueryTaskList', 'db-query');

// 删除查询任务
export const DeleteQueryTask: Fetch<{
  taskId: string
}, DBResponseData<string>> = createGetChannel('DeleteQueryTask', 'db-query');

export const SaveRegularQueryTask: Fetch<SaveRegularQueryTaskParam, DBResponseData<string>> = createPostChannel('SaveRegularQueryTask', 'db-query');

// 浏览器插件登录
export const PluginLogin: Fetch<{ pubKey: string, signBase64: string; }, DBResponseData<{
  token: string;
}>> = createPostChannel('PluginLogin', 'db-query');

// 浏览器插件登出
export const SQLQueryServiceLogout: Fetch<{}, DBResponseData<string>> = createPostChannel('Logout', 'db-query');

// 新建，更新sql语句
export const SaveQuerySql: Fetch<SaveQuerySqlParam, DBResponseData<{
  queryId: number;
}>> = createPostChannel('SaveQuerySql', 'db-query');

// 执行sql查询
export const ExecuteQuery: Fetch<{
  queryId: number;
  queryParams: {
    name: string;
    value: any;
  }[];
}, DBResponseData<{
  taskId: string;
}>> = createPostChannel('ExecuteQuery', 'db-query');

// 轮循sql查询结果
export const GetExecutionResult: Fetch<{
  taskId: string;
}, DBResponseData<ExecutionResultResponse>> = createGetChannel('GetExecutionResult', 'db-query');

// 轮循sql查询结果
export const GetQuerySql: Fetch<{
  queryId: string;
}, DBResponseData<{
  querySql: string;
}>> = createGetChannel('GetQuerySql', 'db-query');

// 取消sql执行任务
export const CancelExecution: Fetch<{
  taskId: string;
}, DBResponseData<string>> = createGetChannel('CancelExecution', 'db-query');


// 获取定时任务详情
export const GetQueryTaskDetail: Fetch<{
  taskId: string;
}, DBResponseData<{
  queryId: number;
  title: string;
  isPublic: boolean;
  isOwner: boolean;
  runType: SQLRunType;
  querySql: string;
  queryParams: any[];
}>> = createGetChannel('GetQueryTaskDetail', 'db-query');

// 获取定时任务详情
export const GetQueryTaskResult: Fetch<{
  taskId: string;
}, DBResponseData<ExecutionResultResponse>> = createGetChannel('GetQueryTaskResult', 'db-query');


// 获取IDA合约列表
export const GetIDAContractList: Fetch<{
  ChainId: string;
  Limit: number;
  Offset: number;
  ContractKey?: string; // 合约名称/合约地址
}, ResponseList<{
  ContractName: string;
  ContractAddr: string;
  ContractType: string;
  DataAssetNum: number; // 登记总量
  Timestamp: number;
}>> = createGetChannel('GetIDAContractList');

// 获取IDA资产列表
export const GetIDADataList: Fetch<{
  ChainId: string;
  Limit: number;
  Offset: number;
  AssetCode?: string | null; // 资产编码
  ContractAddr: string; // 合约地址
}, ResponseList<{
  AssetCode: string; // 资产编码
  Creator: string; // 登记人
  CreatedTime: number;
  UpdatedTime: number;
  IsDeleted: boolean; // 资产状态(0:正常,1:已删除)
}>> = createGetChannel('GetIDADataList');

export const GetIDADataDetail: Fetch<{
  ChainId: string;
  AssetCode: string; // 资产编码
  ContractAddr: string; // 合约地址
}, ResponseData<IDADataDetailResponse>> = createGetChannel('GetIDADataDetail');

// 读取前端配置
export const GetFrontWebConfig = (): Promise<AxiosResponse<{
  APP_IS_SHOW_DB_QUERY: boolean;
}>> => axios.get('/_config.json');
