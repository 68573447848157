import { Search } from '@src/utils/apis';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootReducer } from '@src/store';
import { ContractTypeMap } from '@src/constant/index';

export default function SearchLink({
  children,
  keyWord,
  type,
}: {
  children: string | ReactElement;
  keyWord?: string;
  type?: string;
}) {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const navigate = useNavigate();
  const triggerSearch = useCallback(() => {
    Search({
      Value: keyWord || (children as string),
      ChainId: chainId,
      Type: type,
    }).then((res) => {
      if (res.Data?.Type !== undefined) {
        switch (res.Data.Type) {
          case 0:
            navigate(`/${res.Data.ChainId}/block/${res.Data.Data}`);
            break;
          case 1:
            navigate(`/${res.Data.ChainId}/transaction/${res.Data.Data}`);
            break;
          case 2:
            navigate(`/${res.Data.ChainId}/contract/${res.Data.Data}?ctype=${ContractTypeMap?.[res.Data?.ContractType]}`);
            break;
          case 3:
            navigate(`/${res.Data.ChainId}/chainaccount/deal?accountaddress=${res.Data.Data}`);
            break;
          default:
            break;
        }
      }
    });
  }, [children, keyWord]);
  return <a onClick={triggerSearch}>{children}</a>;
}
