import React from 'react';
import blockDefault from './icon/block-default.svg';
import block from './icon/block.svg';
import convertDefault from './icon/convert-default.svg';
import convert from './icon/convert.svg';
import flyDefault from './icon/fly-default.svg';
import fly from './icon/fly.svg';
import infoDefault from './icon/info-default.svg';
import info from './icon/info.svg';
import safeDefault from './icon/safe-default.svg';
import safe from './icon/safe.svg';
import starDefault from './icon/star-default.svg';
import star from './icon/star.svg';
import successDefault from './icon/success-default.svg';
import success from './icon/success.svg';
import style from './index.module.scss';

const LineMaxWidth = 875;

type IconType = 'block' | 'convert' | 'fly' | 'info' | 'safe' | 'star' | 'success' | '';
type IconStatus = 'Default' | 'Danger' | 'Success' | '' | null | undefined;

const IconMap: { [key: string]: string } = {
  block: block,
  blockDefault: blockDefault,
  convert: convert,
  convertDefault: convertDefault,
  fly: fly,
  flyDefault: flyDefault,
  info: info,
  infoDefault: infoDefault,
  safe: safe,
  safeDefault: safeDefault,
  star: star,
  starDefault: starDefault,
  success: success,
  successDefault: successDefault,
};

function Icon(props: { type: IconType; text?: string; status?: IconStatus; }) {
  const { type, text, status } = props;
  const iconName = status==='Default' ? `${type}${status}` : type;
  return (
    <div className={style.icon}>
      <img src={IconMap[iconName]} alt={type}/>
      {text && <div>{text}</div>}
    </div>
  );
}


const EllipticIconTheme = {
  Default: {
    color: '#B5D0E8',
    bacgroundColor: '#FFFFFF',
    borderColor: '#CEE3F7',
  },
  Danger: {
    color: '#FFFFFF',
    bacgroundColor: '#E34D59',
    borderColor: '#FFFFFF',
  },
  Success: {
    color: '#FFFFFF',
    bacgroundColor: '#185CFF',
    borderColor: '#FFFFFF',
  },
};
function EllipticIcon(props: {text: string , status?: IconStatus}) {
  const { text, status } = props;

  const cssStatus = status? status : 'Success';
  
  return <div className={style.button_icon} style={{color: EllipticIconTheme[cssStatus].color, backgroundColor: EllipticIconTheme[cssStatus].bacgroundColor, borderColor: EllipticIconTheme[cssStatus].borderColor}}>{text}</div>;
}

interface StepType {
  id: string;
  label: string;
  icon: IconType;
  status: IconStatus;
  iconComponent?: string;
}

export default function ProgressBar(props: {
  status: number | undefined
}) {
  const { status=0 } = props;
  const steps: StepType[] = [
    { id: '1', label: '发起链构建交易',  icon: 'fly', status: 'Default' },
    { id: '2', label: '触发交易事件', icon: 'convert', status: 'Default' },
    { id: '3', label: '验证发起链交易信息', icon: 'info', status: 'Default' },
    { id: '4', label: '等待验证', iconComponent: 'EllipticIcon', icon: '', status: 'Default' },
    // { id: '5', label: '接受链收到交易信息', icon: 'safe', status: 'Default' },
    // { id: '6', label: '验证接收链交易信息', icon: 'star', status: 'Default' },
    { id: '5', label: '交易结果上链', icon: 'block', status: 'Default' },
  ];
  let lineItemWidth = 0;
  function updateSteps(stepId: string, ellipticIconStatus?: IconStatus) {
    lineItemWidth = Math.ceil(LineMaxWidth / (steps.length - 1) * (Number(stepId) - 1));
    steps.forEach((item) => {
      if(Number(item.id) <= Number(stepId)){
        if(item.iconComponent === 'EllipticIcon') {
          item.status = ellipticIconStatus;
          item.label = ellipticIconStatus === 'Danger' ? '验证失败' : '验证成功';
        } else {
          item.status = '';
        }
      }
    });
  }

  if([0,1,2].includes(status)){
    updateSteps('3');
  }else if([3].includes(status)){
    updateSteps('5');
  }else if([4].includes(status)){
    updateSteps('4', 'Danger');
  }

  return (
    <div className={style.progressBar}>
      <div className={style.line} style={{ width: LineMaxWidth }}>
        <div className={style.line_item} style={{ width: lineItemWidth }}></div>
        <div className={style.line_icons} style={{ width: LineMaxWidth+50 }}>
          {
            steps.map((step) => {
              if(step.iconComponent === 'EllipticIcon'){
                return <EllipticIcon key={step.id} text={step.label} status={step.status} />;
              }
              return <Icon key={step.id} type={step.icon} status={step.status} text={step.label} />;
            })
          }
        </div>
      </div>
    </div>
  );
}
