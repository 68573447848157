import React, { useEffect, useState } from 'react';
import { DatePicker, Table, TableColumn, Text, Button, Input } from 'tea-component';
const { pageable, autotip } = Table.addons;
import PageBox from '../../../components/ui/PageBox';
import { useNavigate, Link } from 'react-router-dom';
import { GetCrossTxList } from '@src/utils/apis';
import { CrossTxListItem, GetCrossTxListParam } from '@src/models';
import { useParams } from 'react-router-dom';
import { TextTheme } from '@src/constant';
import { formatTime } from '@src/utils/tools';
import style from './index.module.scss';
import { CrossChainLink } from '@src/routes/main/components/CrossChainLink';

const { RangePicker } = DatePicker;

const CrossTxListStatusMap: { [key: string]: { text: string; theme: TextTheme } } = {
  0: { text: '进行中', theme: 'warning' },
  1: { text: '成功', theme: 'success' },
  2: { text: '失败', theme: 'danger' },
};


export function CrossChainTable(props: {
  SubChainId?: string
}) {
  const navigate = useNavigate();
  const { chainId } = useParams();
  const [crossTxList, setCrossTxList] = useState<CrossTxListItem[]>([]);
  const [queryPage, setQueryPage] = useState({ pageIndex: 1, pageSize: 10, recordCount: 0 });
  const [searchValue, setSearchValue] = useState<{
    CrossId?: string;
    FromChainName?: string;
    ToChainName?: string;
    StartTime?: number | null;
    EndTime?: number | null;
  }>({
    CrossId: '',
    FromChainName: '',
    ToChainName: '',
    StartTime: 0,
    EndTime: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const updateQueryPage = (data: { pageIndex?: number; pageSize?: number; recordCount?: number }) => {
    setQueryPage({ ...queryPage, ...data });
  };

  const upDateSearchValue = (data: {
    CrossId?: string;
    FromChainName?: string;
    ToChainName?: string;
    StartTime?: number | null;
    EndTime?: number | null;
  }) => {
    setSearchValue({ ...searchValue, ...data });
  };

  const onSearch = ({ pageIndex = 1, pageSize = 10 }) => {
    setIsLoading(true);
    const params: GetCrossTxListParam = {
      ChainId: chainId,
      Offset: pageIndex - 1,
      Limit: pageSize,
      CrossId: searchValue.CrossId,
      FromChainName: searchValue.FromChainName,
      ToChainName: searchValue.ToChainName,
      StartTime: searchValue.StartTime ? Math.floor(searchValue.StartTime / 1000) : 0,
      EndTime: searchValue.EndTime ? Math.ceil(searchValue.EndTime / 1000) : 0,
    };

    if (props?.SubChainId) {
      params.SubChainId = props?.SubChainId;
    }

    GetCrossTxList(params).then((res) => {
      if (res.GroupList?.length) {
        setCrossTxList(res.GroupList);
        updateQueryPage({ pageIndex, pageSize, recordCount: res.TotalCount });
      } else {
        setCrossTxList([]);
        updateQueryPage({ pageIndex, pageSize, recordCount: 0 });
      }

    }).catch((e) => {
      console.log(e);
      return;
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columns: TableColumn<CrossTxListItem>[] = [
    {
      key: 'CrossId',
      header: '跨链ID',
      align: 'left',
      width: 100,
      render: (record: CrossTxListItem) => {
        return <Link
          to={`/${chainId}/subchain/crossTransactionDetail?crossId=${record.CrossId}`}>{record.CrossId}</Link>;
      },
    },
    {
      key: 'FromChainName',
      header: '发起链名',
      align: 'left',
      render: (record: CrossTxListItem) => {
        return <CrossChainLink chainId={chainId} subChainId={record.FromChainId} subIsMainChain={record.FromIsMainChain}
          name={record.FromChainName}/>;
      },
    },
    {
      key: 'ToChainName',
      header: '接收链名',
      align: 'left',
      render: (record: CrossTxListItem) => {
        return <CrossChainLink chainId={chainId} subChainId={record.ToChainId} subIsMainChain={record.ToIsMainChain}
          name={record.ToChainName}/>;
      },
    },
    {
      key: 'Status',
      header: '跨链交易状态',
      align: 'left',
      width: 168,
      render: (record: CrossTxListItem) => {
        return <Text
          theme={CrossTxListStatusMap[record?.Status ?? '']?.theme}>{CrossTxListStatusMap[record?.Status ?? '']?.text}</Text>;
      },
    },
    {
      key: 'Timestamp',
      header: '跨链发起时间',
      align: 'left',
      width: 168,
      render: (record: CrossTxListItem) => {
        return formatTime(record.Timestamp * 1000);
      },
    },
    {
      key: 'setting',
      header: '操作',
      align: 'left',
      width: 100,
      render: (record: CrossTxListItem) => {
        return (
          <>
            <Button type="link"
              onClick={() => navigate(`/${chainId}/subchain/crossTransactionDetail?crossId=${record.CrossId}`)}>查看</Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (chainId) {
      onSearch({
        pageIndex: queryPage.pageIndex,
        pageSize: queryPage.pageSize,
      });
    }
  }, [chainId]);

  return (
    <>
      <div className={style.sub_chain_list_search}>
        <div>
          <Text>起止时间：</Text>
          <RangePicker
            clearable
            onChange={value => {
              upDateSearchValue({
                StartTime: value[0] ? value[0].startOf('day').valueOf() : null,
                EndTime: value[1] ? value[1].endOf('day').valueOf() : null,
              });
            }}
          />
        </div>
        <div>
          <Text>跨链ID：</Text>
          <Input
            value={searchValue.CrossId}
            size="m"
            onChange={(value) => {
              upDateSearchValue({ CrossId: value });
            }}
            placeholder="请输入跨链ID"
          />
        </div>
        <div>
          <Text>发起链：</Text>
          <Input
            value={searchValue.FromChainName}
            size="m"
            onChange={(value) => {
              upDateSearchValue({ FromChainName: value });
            }}
            placeholder="请输入发起方名称"
          />
        </div>
        <div>
          <Text>接收链：</Text>
          <Input
            value={searchValue.ToChainName}
            size="m"
            onChange={(value) => {
              upDateSearchValue({ ToChainName: value });
            }}
            placeholder="请输入接收方名称"
          />
        </div>
        <Button
          type="primary"
          onClick={() => {
            onSearch({
              pageIndex: 1,
              pageSize: queryPage.pageSize,
            });
          }}
        >
          筛选
        </Button>
      </div>
      <Table
        className={style.detail_table}
        records={crossTxList}
        recordKey="CrossId"
        bordered={true}
        columns={columns}
        addons={[
          autotip({
            isLoading,
          }),
          pageable({
            recordCount: queryPage.recordCount,
            pageIndex: queryPage.pageIndex,
            pageSize: queryPage.pageSize,
            onPagingChange: (query) => {
              onSearch(query);
            },
          }),
        ]}
      />
    </>
  );
}

export function CrossChainList() {
  return (
    <PageBox title="跨链交易列表">
      <CrossChainTable/>
    </PageBox>
  );
}
