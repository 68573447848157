import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import { all } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { ChainReducerState, chainReducer, CHAINSAGA } from './chain';
import { TrustedChainIdState, trustedChainIdReducer } from './trustedChainId';
import {
  GetBlockListParamState,
  blockListParamReducer,
  GetTxListParamInfo,
  txListParamReducer,
  GetContractListParamInfo,
  contractListParamReducer,
  GetUserListParamState,
  userListParamReducer,
  nodeListParamReducer,
  GetNodeListParamState,
  orgListParamReducer,
  GetOrgListParamState,
} from './listInfo';

import { connectRouter, routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

export interface RootReducer {
  chainReducer: ChainReducerState;
  trustedChainIdReducer: TrustedChainIdState;
  blockListParamReducer: GetBlockListParamState;
  txListParamReducer: GetTxListParamInfo;
  contractListParamReducer: GetContractListParamInfo;
  userListParamReducer: GetUserListParamState;
  nodeListParamReducer: GetNodeListParamState;
  orgListParamReducer: GetOrgListParamState;
}

const reduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION__?.();
const rootReducer = combineReducers({
  chainReducer,
  trustedChainIdReducer,
  blockListParamReducer,
  txListParamReducer,
  contractListParamReducer,
  userListParamReducer,
  nodeListParamReducer,
  orgListParamReducer,
  Router: connectRouter(history),
});

function * rootSaga() {
  yield all([...CHAINSAGA]);
}

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware, routerMiddleware(history));
const enhancers =
  process.env.NODE_ENV === 'production' || reduxDevtools === undefined
    ? compose(middleware)
    : compose(middleware, reduxDevtools);
const store = createStore(rootReducer, enhancers);
sagaMiddleware.run(rootSaga);
export default store;
