import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Main from './routes/main';
import AddChain from './routes/AddChain';
import { AppContext, AppContextValue } from '@components/context';
import {
  ChainMakerPluginAccount,
  getChainMakerPluginAccount,
  removeChainMakerPluginAccount,
  setChainMakerPluginAccount,
} from '@utils/cookies';
import Version from '@src/routes/main/version';
import { GetFrontWebConfig } from '@utils/apis';

function App() {
  const [account, setAccount] = useState<ChainMakerPluginAccount | null>(getChainMakerPluginAccount());
  const [appConfig, setAppConfig] = useState<AppContextValue['appConfig']>({
    APP_IS_SHOW_DB_QUERY:true,
  });
  const setAccountCall = useCallback((token: ChainMakerPluginAccount | null, cb?: Function) => {
    setAccount(token);
    if (token) {
      setChainMakerPluginAccount(token);
    } else {
      removeChainMakerPluginAccount();
    }
    cb?.();
  }, []);

  useEffect(()=>{
    GetFrontWebConfig().then(res=>{
      setAppConfig(res.data);
    });
  },[]);
  return (
    <BrowserRouter>
      <AppContext.Provider value={{ account, setAccount: setAccountCall, appConfig }}>
        <Routes>
          <Route path="addchain" element={<AddChain/>}/>
          <Route path=":chainId/*" element={<Main/>}/>
          <Route path="/" element={<Navigate to="home"/>}/>
          <Route path="upgrade" element={<Version.UpgradePage/>}/>
        </Routes>
        <Version.Footer/>
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
