import React, {  useEffect, useState, useRef } from 'react';
import {  Text } from 'tea-component';
import { SubChainCrossChainListItem } from '@src/models';
import { SubChainCrossChainList } from '@src/utils/apis';
import { useParams } from 'react-router-dom';
import { getAllUrlParams } from '@src/utils/tools';
import style from './index.module.scss';

const colors = ['#499BFC', '#8671F0', '#52CC6F', '#FF9236', '#FD7063'];
const minWith = 50;
const maxWith = 400;
const zeroWith = 5;

function measureTextWidth(text: string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  const body = document.body;
  const computedStyle = window.getComputedStyle(body);
  const fontFamily = computedStyle.getPropertyValue('font-family');
  const fontSize = computedStyle.getPropertyValue('font-size');
  context.font = `${fontSize} ${fontFamily}`;
  const metrics = context.measureText(text);
  const width = metrics.width;
  // 清理
  setTimeout(() => {
    canvas.remove();
  }, 10);
  return width;
}

export function CrossChainRank(){
  const { chainId } = useParams();
  const { subChainId } = getAllUrlParams();
  const [crossChainList, setCrossChainList] = useState<SubChainCrossChainListItem[]>([]);
  const [labelWidth, setLabelWidth] = useState(80);

  const ref = useRef<HTMLDivElement>(null);

  const maxTxNum = crossChainList?.[0]?.TxNum ?? 1;

  useEffect(() => {
    SubChainCrossChainList({ChainId: chainId, SubChainId: subChainId}).then((res) => {
      const list = res.GroupList;
      // for(let i = 1; i < 6; i++){
      //     list.push({ChainName: "管理链", ChainId: i, TxNum: 100/i, Ranking: i});
      // }
      let maxWidth = 0;
      list.forEach((item) => {
        maxWidth = Math.max(maxWidth, measureTextWidth(item.ChainName));
      });
      setLabelWidth(maxWidth);

      setCrossChainList(list);
    });
  }, [chainId, subChainId]);

  return (
    <div ref={ref}>
      {crossChainList.map((item, index) => {
        let progressWidth = (item?.TxNum ?? 0) / maxTxNum * maxWith;
        if(progressWidth < minWith){
          progressWidth = minWith;
        }
        if((item?.TxNum ?? 0) === 0){
          progressWidth = zeroWith;
        }

        const color = colors[(index%colors.length)];
        return (
          <div key={item?.ChainId} className={style.chain_rank_item}>
            <Text className={style.chain_rank_name} style={{width: `${labelWidth}px`}}>{item?.ChainName}</Text>
            <div className={style.chain_rank_progress} style={{width: `${progressWidth}px`, backgroundColor: color}}></div>
            <Text theme="weak">{item?.TxNum}</Text>
          </div>
        );
      })}
    </div>
  );
}