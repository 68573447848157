import React, { useEffect, useState } from 'react';
import { Form, Table, Row, Col, Input, Icon } from 'tea-component';
import detailstyle from '../detail.module.scss';
import PageBox from '../../../components/ui/PageBox';
import ProgressBar from './ProgressBar/index';
import { CrossTxDetailInfo } from '@src/models';
import { GetCrossTxDetail } from '@src/utils/apis';
import { useParams } from 'react-router-dom';
import { getAllUrlParams, formatTime } from '@src/utils/tools';
import liststyle from '../list.module.scss';
import { IconTitle } from './IconTitle';
import { CrossTxStatusMap } from '@src/constant';

const TxStatusIconMap: { [key: string]: string } = {
  0: 'success',
  1: 'error',
};

export function CrossTransactionDetail() {
  const { chainId } = useParams();
  const { crossId } = getAllUrlParams();

  const [crossTxDetailInfo, setCrossTxDetailInfo] =
    useState<CrossTxDetailInfo | null>(null);

  useEffect(() => {
    GetCrossTxDetail({ ChainId: chainId, CrossId: crossId }).then((res) => {
      setCrossTxDetailInfo(res.Data);
    });
  }, [chainId, crossId]);

  const getContractParametersList = () => {
    if (!crossTxDetailInfo?.Parameter) {
      return [];
    }
    try {
      return [JSON.parse(crossTxDetailInfo?.Parameter ?? '{}')];
    } catch (e) {
      return [{ key: '参数解析失败', value: '参数解析失败' }];
    }
  };

  const getCrossTxUrl = (
    IsMainChain: boolean | undefined,
    TxUrl: string | undefined,
    TxId: string|undefined

  ) => {
    let url = TxUrl;
    if (IsMainChain) {
      url = `/${chainId}/transaction/${TxId}`;
    }
    return url;
  };

  const returnLinkOrText = ({TxId, TxUrl}: {TxId?: string, TxUrl?: string}) => {
    if (TxId && TxUrl) {
      return (
        <a href={TxUrl} type="link">
          {TxId}
        </a>
      );
    } else if (TxId && !TxUrl) {
      return <span className={detailstyle.invalid_link}>{TxId}</span>;
    } else {
      return <span className={detailstyle.invalid_link}>/</span>;
    }
  };

  return (
    <PageBox title="跨链交易详情">
      <div className={detailstyle.detail}>
        <Form.Title>{IconTitle('交易基本信息')}</Form.Title>
        <Row>
          <Col span={24}>
            <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
              <Form.Item label="跨链ID">
                <Form.Text>{crossTxDetailInfo?.CrossId}</Form.Text>
              </Form.Item>
              <Form.Item label="跨链方向">
                <Form.Text>
                  {crossTxDetailInfo?.CrossDirection?.FromChain} ----&gt;{' '}
                  {crossTxDetailInfo?.CrossDirection?.ToChain}
                </Form.Text>
              </Form.Item>
              <Form.Item label="跨链发起时间">
                <Form.Text>
                  {formatTime((crossTxDetailInfo?.Timestamp ?? 0) * 1000)}
                </Form.Text>
              </Form.Item>
              <Form.Item label="跨链耗时">
                <Form.Text>{crossTxDetailInfo?.CrossDuration} s</Form.Text>
              </Form.Item>
              <Form.Item label="跨链交易状态">
                <Form.Text>
                  {CrossTxStatusMap[crossTxDetailInfo?.Status ?? '']?.text}
                </Form.Text>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Form.Title>{IconTitle('目标交易内容')}</Form.Title>
        <Row>
          <Col span={24}>
            <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
              <Form.Item label="合约名称">
                <Form.Text>{crossTxDetailInfo?.ContractName}</Form.Text>
              </Form.Item>
              <Form.Item label="合约名称调用方法">
                <Form.Text>{crossTxDetailInfo?.ContractMethod}</Form.Text>
              </Form.Item>
              <Form.Item label="合约调用入参">
                <Table
                  className={liststyle.detail_table}
                  compact={false}
                  records={getContractParametersList()}
                  bordered={true}
                  disableTextOverflow={true}
                  columns={[
                    {
                      key: 'index',
                      header: '#',
                      align: 'left',
                      width: 60,
                      render: (_item, _name, index) => index + 1,
                    },
                    {
                      key: 'key',
                      header: 'Key',
                      align: 'left',
                      width: 200,
                    },
                    {
                      key: 'value',
                      header: 'Value',
                      align: 'left',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item label="合约执行结果">
                <Input size="full" value={crossTxDetailInfo?.ContractResult} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Form.Title>{IconTitle('交易发起方信息')}</Form.Title>
        <Row>
          <Col span={24}>
            <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
              <Form.Item label="链名称">
                <Form.Text>
                  {crossTxDetailInfo?.FromChainInfo?.ChainName}
                </Form.Text>
              </Form.Item>
              <Form.Item label="用户合约名称">
                <Form.Text>
                  {crossTxDetailInfo?.FromChainInfo?.ContractName}
                </Form.Text>
              </Form.Item>
              <Form.Item label="发起链交易ID">
                <Form.Text>
                  {returnLinkOrText({
                    TxUrl: getCrossTxUrl(
                      crossTxDetailInfo?.FromChainInfo?.IsMainChain,
                      crossTxDetailInfo?.FromChainInfo?.TxUrl,
                      crossTxDetailInfo?.FromChainInfo?.TxId
                    ),
                    TxId: crossTxDetailInfo?.FromChainInfo?.TxId,
                  })}
                  <Icon
                    className={detailstyle.icon_style}
                    type={
                      TxStatusIconMap?.[
                        crossTxDetailInfo?.FromChainInfo?.TxStatus ?? ''
                      ] ?? ''
                    }
                  />
                </Form.Text>
              </Form.Item>
              <Form.Item label="GAS">
                <Form.Text>
                  {crossTxDetailInfo?.FromChainInfo?.Gas ?? '-'}
                </Form.Text>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Form.Title>{IconTitle('交易接收方信息')}</Form.Title>
        <Row>
          <Col span={24}>
            <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
              <Form.Item label="链名称">
                <Form.Text>
                  {crossTxDetailInfo?.ToChainInfo?.ChainName}
                </Form.Text>
              </Form.Item>
              <Form.Item label="用户合约名称">
                <Form.Text>
                  {crossTxDetailInfo?.ToChainInfo?.ContractName}
                </Form.Text>
              </Form.Item>
              <Form.Item label="目标链交易ID">
                <Form.Text>
                  {returnLinkOrText({
                    TxId: crossTxDetailInfo?.ToChainInfo?.TxId,
                    TxUrl: getCrossTxUrl(
                      crossTxDetailInfo?.ToChainInfo?.IsMainChain,
                      crossTxDetailInfo?.ToChainInfo?.TxUrl,
                      crossTxDetailInfo?.ToChainInfo?.TxId
                    ),
                  })}
                  {crossTxDetailInfo?.ToChainInfo?.TxId && (
                    <Icon
                      className={detailstyle.icon_style}
                      type={
                        TxStatusIconMap?.[
                          crossTxDetailInfo?.ToChainInfo?.TxStatus ?? ''
                        ] ?? ''
                      }
                    />
                  )}
                </Form.Text>
              </Form.Item>
              <Form.Item label="GAS">
                <Form.Text>
                  {crossTxDetailInfo?.ToChainInfo?.Gas ?? '-'}
                </Form.Text>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Form.Title>{IconTitle('跨链状态')}</Form.Title>
        <ProgressBar status={crossTxDetailInfo?.Status} />
      </div>
    </PageBox>
  );
}
