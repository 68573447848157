import React, { useEffect, useState } from 'react';
import { Table, Text, Button, Input, Bubble } from 'tea-component';
const { pageable, autotip } = Table.addons;
import PageBox from '../../../components/ui/PageBox';
import { useNavigate, Link } from 'react-router-dom';
import { CrossSubChainList } from '@src/utils/apis';
import { CrossSubChainListItem, } from '@src/models';
import { useParams } from 'react-router-dom';
import { SubChainStatusMap } from '@src/constant';
import style from './index.module.scss';
import { formatTime } from '@src/utils/tools';

export function SubChainList() {
  const navigate = useNavigate();
  const { chainId } = useParams();
  const [crossSubChainList, setCrossSubChainList] = useState<CrossSubChainListItem[]>([]);
  const [queryPage, setQueryPage] = useState({ pageIndex: 1, pageSize: 10, recordCount: 0 });
  const [subChainName, setSubChainName] = useState('');
  const [subChainId, setSubChainId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const updateQueryPage = (data: { pageIndex?: number; pageSize?: number; recordCount?: number }) => {
    setQueryPage({ ...queryPage, ...data });
  };

  const onSearch = ({ pageIndex = 1, pageSize = 10 }) => {
    setIsLoading(true);
    CrossSubChainList({
      ChainId: chainId,
      Offset: pageIndex - 1,
      Limit: pageSize,
      SubChainId: subChainId,
      SubChainName: subChainName,
    }).then((res) => {
      setCrossSubChainList(res.GroupList);
      updateQueryPage({ pageIndex, pageSize, recordCount: res.TotalCount });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (chainId) {
      onSearch({
        pageIndex: queryPage.pageIndex,
        pageSize: queryPage.pageSize
      });
    }
  }, [chainId]);

  return (
    <PageBox title="子链列表">
      <div className={style.sub_chain_list_search}>
        <div>
          <Text>子链名称：</Text>
          <Input
            value={subChainName}
            size="l"
            onChange={(value) => {
              setSubChainName(value);
            }}
            placeholder="输入子链名称"
          />
        </div>
        <div>
          <Text>链ID：</Text>
          <Input
            value={subChainId}
            size="l"
            onChange={(value) => {
              setSubChainId(value);
            }}
            placeholder="请输入链ID"
          />
        </div>
        <Button
          type="primary"
          onClick={() => {
            onSearch({
              pageIndex: 1,
              pageSize: queryPage.pageSize
            });
          }}
        >
          筛选
        </Button>
      </div>
      <Table
        className={style.detail_table}
        records={crossSubChainList}
        recordKey="SubChainId"
        bordered={true}
        columns={[
          {
            key: 'SubChainName',
            header: '子链名称',
            align: 'left',
            render: (record: CrossSubChainListItem) => {
              return <Link to={`/${chainId}/subchain/subChainDetail?subChainId=${record.SubChainId}`}>{record.SubChainName}</Link>;
            },
          },
          {
            key: 'SubChainId',
            header: 'SubchainID',
            align: 'left',
            render: (record: CrossSubChainListItem) => {
              return <Link to={`/${chainId}/subchain/subChainDetail?subChainId=${record.SubChainId}`}>{record.SubChainId}</Link>;
            },
          },
          {
            key: 'BlockHeight',
            header: '区块高度',
            align: 'left',
          },
          {
            key: 'CrossTxNum',
            header: '累计跨链交易数',
            align: 'left',
          },
          {
            key: 'CrossContractNum',
            header: '累计跨链合约数据',
            align: 'left',
          },
          {
            key: 'Status',
            header: '运行状态',
            align: 'left',
            render: (record: CrossSubChainListItem) => {
              return SubChainStatusMap?.[record.Status].text;
            }
          },
          {
            key: 'Timestamp',
            header: '同步时间',
            align: 'left',
            render: (record) => formatTime(record.Timestamp * 1000),
            width: 200
          },
          {
            key: 'setting',
            header: '操作',
            render: (record: CrossSubChainListItem) => {
              return (
                <>
                  <a target='_blank' onClick={() => navigate(`/${chainId}/subchain/subChainDetail?subChainId=${record.SubChainId}`)}>查看</a>
                  {' '}
                  {!record.ExplorerUrl ? <Bubble content="仅内部访问">浏览器</Bubble> : <a target='_blank' href={record.ExplorerUrl} rel="noreferrer" >浏览器</a>}
                </>
              );
            }
          },
        ]}
        addons={[
          autotip({
            isLoading,
          }),
          pageable({
            recordCount: queryPage.recordCount,
            pageIndex: queryPage.pageIndex,
            pageSize: queryPage.pageSize,
            onPagingChange: (query) => {
              onSearch(query);
            },
          }),
        ]}
      />
    </PageBox>
  );
}
