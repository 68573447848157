import React, { useEffect, useMemo, useState } from 'react';
import PageBox from '@components/ui/PageBox';
import { Form } from 'tea-component';
import detailstyle from '../../detail.module.scss';
import { GetContractDetail, GetFTContractDetail, GetNFTContractDetail } from '@src/utils/apis';
import { ContractInfo, FTContractDetail, NFTContractDetail } from '@src/models';
import { Navigate, Route, Routes, useParams } from 'react-router';
import Transaction from '../components/Transaction';
import { NFTAndFTTransfer } from '../components/NFTAndFTTransfer';
import CodeEdit from '../components/CodeEdit';
import Event from '../components/Event';
import Version from '../components/Version';
import style from './index.module.scss';
import Nft from '../components/Nft';
import { PositionList } from '../components/PositionList';
import { formatUnixTime, getAllUrlParams } from '@src/utils/tools';
import { ContractStatus } from '@src/utils/enums';
import { Link, NavLink } from 'react-router-dom';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
import Transfer from '../components/Transfer';
import { ResponseData } from '@src/utils/apis/type';
import { UserAddress } from '@src/components/ui/AttrShow/UserAddress';
import IdaContractList from '@src/routes/main/ida/ida-contract-list';

export default function ContractDetail() {
  const { ctype = '' } = getAllUrlParams(); // 合约类型
  const { contractAddr = '' } = useParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId ?? '', [currentChain]);
  const [detail, setDetail] = useState<ContractInfo | FTContractDetail | NFTContractDetail | null>(null);

  const getDetailCallback = (res: ResponseData<ContractInfo | FTContractDetail | NFTContractDetail>) => {
    if (res.Data) {
      res.Data.ContractStatusText = ContractStatus[res.Data.ContractStatus] || '--';
      setDetail(res.Data);
    }
  };

  useEffect(() => {
    if (ctype === 'nft') {
      GetNFTContractDetail({ ChainId: chainId, ContractAddr: contractAddr }).then(getDetailCallback);
    } else if (ctype === 'ft') {
      GetFTContractDetail({ ChainId: chainId, ContractAddr: contractAddr }).then(getDetailCallback);
    } else {
      GetContractDetail({ ChainId: chainId, ContractKey: contractAddr }).then(getDetailCallback);
    }
  }, [chainId, contractAddr, ctype]);

  const createTimestamp = ['ft', 'nft'].includes(ctype) ? formatUnixTime((detail as FTContractDetail)?.CreateTimestamp || undefined) : formatUnixTime((detail as ContractInfo)?.Timestamp || undefined);

  return (
    <PageBox title="合约详情">
      <div className={`${detailstyle.detail} ${style.detail}`}>
        <Form.Title>合约基本信息</Form.Title>
        <Form hideLabel={false} fixedLabelWidth={150} layout="fixed">
          <Form.Item label="合约名称">
            <Form.Text>{detail?.ContractName || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="合约状态">
            <Form.Text>{detail?.ContractStatusText || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="合约地址">
            <Form.Text>{detail?.ContractAddr || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="当前版本">
            <Form.Text>{detail?.Version || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="合约类型">
            <Form.Text>{detail?.ContractType || '--'}</Form.Text>
          </Form.Item>
          {
            ctype === 'ida' && <Form.Item label="登记总量">
              <Form.Text>{(detail as ContractInfo)?.DataAssetNum || '--'}</Form.Text>
            </Form.Item>}
          {ctype === 'ft' && (
            <Form.Item label="Token简称">
              <Form.Text>{(detail as FTContractDetail)?.ContractSymbol || '--'}</Form.Text>
            </Form.Item>
          )}
          {['nft', 'ft'].includes(ctype) && (
            <Form.Item label="发行总量">
              <Form.Text>{detail?.TotalSupply === undefined ? '--' : detail?.TotalSupply}</Form.Text>
            </Form.Item>
          )}
          <Form.Item label="虚拟机类型">
            <Form.Text>{detail?.RuntimeType || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="创建用户">
            <Form.Text>
              {/* <Bubble content={detail?.CreatorAddr || ''}>
                {authType === 'permissionedwithcert' ? detail?.CreateSender || '--' : detail?.CreatorAddr || '--'}
              </Bubble> */}
              <UserAddress UserAddrBns={detail?.CreatorAddrBns} Sender={detail?.CreateSender} UserAddr={detail?.CreatorAddr}/>
            </Form.Text>
          </Form.Item>
          <Form.Item label="创建时间">
            <Form.Text>{createTimestamp}</Form.Text>
          </Form.Item>
          <Form.Item label="创建交易id">
            <Form.Text>
              {detail?.TxId ? <Link to={`/${chainId}/transaction/${detail.TxId}`}>{detail.TxId}</Link> : '--'}
            </Form.Text>
          </Form.Item>
        </Form>
      </div>
      <div className={style.navs}>
        <NavLink
          to={`/${chainId}/contract/${contractAddr}/deal${location.search}`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          交易信息
        </NavLink>
        <NavLink
          to={`/${chainId}/contract/${contractAddr}/code${location.search}`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          合约源码
        </NavLink>
        {['ida'].includes(ctype) && <NavLink
          to={`/${chainId}/contract/${contractAddr}/ida${location.search}`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          数据资产总览
        </NavLink>}
        {['nft', 'ft'].includes(ctype) && (
          <NavLink
            to={`/${chainId}/contract/${contractAddr}/transfer${location.search}`}
            className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
          >
            流转记录
          </NavLink>
        )}
        {['nft', 'ft'].includes(ctype) && (<NavLink
          to={`/${chainId}/contract/${contractAddr}/positions${location.search}`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          持有者
        </NavLink>)}
        {ctype === 'nft' && (<NavLink
          to={`/${chainId}/contract/${contractAddr}/nft${location.search}`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          NFT总览
        </NavLink>)}
        <NavLink
          to={`/${chainId}/contract/${contractAddr}/event${location.search}`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          事件信息
        </NavLink>
        <NavLink
          to={`/${chainId}/contract/${contractAddr}/version${location.search}`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          版本记录
        </NavLink>

      </div>
      <Routes>
        <Route path="deal" element={<Transaction name={detail?.ContractName} />} />
        <Route path="ida" element={<IdaContractList />} />
        <Route path="event" element={<Event name={detail?.ContractName} />} />
        <Route path="positions" element={<PositionList />} />
        <Route path="nft" element={<Nft />} />
        <Route path="transfer" element={['nft', 'ft'].includes(ctype) ? <NFTAndFTTransfer /> : <Transfer name={detail?.ContractName} type={detail?.ContractType}/>} />
        <Route
          path="code"
          element={<CodeEdit version={detail?.Version} name={detail?.ContractName} />}
        />
        <Route path="version" element={<Version name={detail?.ContractName} />} />
        <Route path="*" element={<Navigate replace to={`deal${location.search}`} />} />
      </Routes>
    </PageBox>
  );
}
