import axios from 'axios';
import { Fetch, ResponseInfo } from './type';
import mock from '../mock';
import { notification } from 'tea-component';
import { transformDesc, transformTitle } from './notification';
import { getChainMakerPluginAccount, removeChainMakerPluginAccount } from '@utils/cookies';

// 是否开启mock
const ISMOCK = false;
// mock 情况下接口为一个取配置数据的promise函数
const MOCKFUNC = (url: string) => (params: any, transformResponse?: (resp: ResponseInfo<any>) => void) =>
  new Promise((resolve) => {
    setTimeout(() => {
      transformResponse?.({
        data: mock[url],
        status: 0,
        statusText: '',
        headers: {},
        config: {},
      });
      resolve(mock[url].Response);
    });
  });
// const BASEURL = 'http://192.168.1.108:8888/chainmaker';
const instance = axios.create({
  baseURL: '/chainmaker/', // process.env.NODE_ENV === 'production' ? BASEURL : '/chainmaker',
});

const dbQueryInstance = axios.create({
  baseURL: '/dquery/sqlQuery', // process.env.NODE_ENV === 'production' ? BASEURL : '/chainmaker',
});

instance.interceptors.response.use(
  (response: ResponseInfo<any>) => {
    const resp: any = response?.data?.Response;
    if (resp?.Error?.Code) {
      notification.error({
        title: transformTitle(response?.config?.params?.cmb),
        description: transformDesc(resp),
        unique: true,
        duration: 3000,
      });
    }
    if (resp) {
      return resp;
    }
  },
  (error) => Promise.reject(error),
);

dbQueryInstance.interceptors.response.use(
  (response) => {
    const resp: any = response?.data;
    if (resp?.code != 200) {
      notification.error({
        title: transformTitle(response?.config?.params?.cmb),
        description: resp?.msg,
        unique: true,
        duration: 3000,
      });
      setTimeout(() => {
        if ([1002, 401].includes(resp.code)) {
          // 1002 是token过期，没有权限，token传的格式不对等权限相关错误
          window.location.href = '/';
          removeChainMakerPluginAccount();
        }
      }, 3_000);
      throw new Error(resp?.msg);
    }
    if (resp) {
      return resp;
    }
  },
  (error) => Promise.reject(error),
);

// 对错误信息进行默认处理的get请求 封装函数
export const createGetChannel = (cmd: string, service: 'db-query' | 'chainmaker' = 'chainmaker'): Fetch<any, any> => {
  if (ISMOCK) {
    return MOCKFUNC(cmd);
  }
  return (params: any, transformResponse?: (resp: ResponseInfo<any>) => void) => {
    const account = getChainMakerPluginAccount();
    return (service === 'db-query' ? dbQueryInstance : instance).get('', {
      params: { ...params, cmb: cmd },
      transformResponse,
      headers: {
        Authorization: account ? `Bearer ${account.token}` : '',
      },
    });
  };
};

// 对错误信息进行默认处理的post请求 封装函数
export const createPostChannel = (cmd: string, service: 'db-query' | 'chainmaker' = 'chainmaker'): Fetch<any, any> => {
  if (ISMOCK) {
    return MOCKFUNC(cmd);
  }
  return (data: any) => {
    const account = getChainMakerPluginAccount();
    return (service === 'db-query' ? dbQueryInstance : instance).post(
      '',
      {
        ...data,
      },
      {
        params: { cmb: cmd },
        headers: {
          Authorization: account ? `Bearer ${account.token}` : '',
        },
      },
    );
  };
};

// 单纯处理的post请求 的封装函数
export const createPostFetch = (url: string): Fetch<any, any> => {
  if (ISMOCK) {
    return MOCKFUNC(url);
  }
  return (data: any) =>
    axios.post(
      '',
      {
        ...data,
      },
      {
        params: { cmb: url },
      },
    );
};
