import React, { useEffect, useState } from 'react';
import style from '../Detail/index.module.scss';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/ext-language_tools';
import { useParams } from 'react-router-dom';
import { GetContractCodeRes } from '@src/models';
import { GetContractCode } from '@src/utils/apis';

export default function CodeEdit({ version, name }: { version?: string; name?: string }) {
  const { chainId } = useParams();
  const [code, setCode] = useState<GetContractCodeRes | null>(null);
  useEffect(() => {
    if (!name) {
      return;
    }
    GetContractCode({
      ChainId: chainId,
      ContractName: name,
    }).then((res) => {
      if (res.Data) {
        setCode(res.Data);
      }
    });
  }, [chainId, name, version]);

  return (
    <>
      {code?.ContractByteCode || code?.ContractCode || code?.ContractAbi
        ? (
          <div>
            {code?.ContractCode && (
              <>
                <div className={style.subtitle}>合约源码</div>
                <AceEditor
                  mode="golang"
                  theme="xcode"
                  readOnly={true}
                  showPrintMargin={false}
                  showGutter={true}
                  highlightActiveLine={false}
                  value={code?.ContractCode}
                  style={{
                    width: 'auto',
                    border: '1px solid #ccc',
                  }}
                />
              </>
            )}
            {code?.ContractByteCode && (
              <>
                <div className={style.subtitle}>合约Bytecode</div>
                <div className={style.text}>{code?.ContractByteCode}</div>
              </>
            )}
            {code?.ContractAbi && (
              <>
                <div className={style.subtitle}>合约ABI</div>
                <div className={style.text}>{code?.ContractAbi}</div>
              </>
            )}
          </div>
        )
        : (
          <>
            <div className="empty_list"></div>
            <div className={style.empty_word}>暂未提供合约源码</div>
          </>
        )}
    </>
  );
}
