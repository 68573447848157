export function getAllUrlParams() {
  const queryString = window.location.search.slice(1);
  const params: {[key: string]: string} = {};

  if (queryString) {
    const keyValues = queryString.split('&');

    for (let i = 0; i < keyValues.length; i++) {
      const keyValue = keyValues[i].split('=');
      const key = decodeURIComponent(keyValue[0]);
      const value = decodeURIComponent(keyValue[1] || '');
      params[key] = (value);
    }
  }

  return params;
}


export function numberToEnUS(data: string | number | undefined | null): string {
  if (data === 0 || data === '0') {
    return '0';
  }
  if (data === undefined || data === null || data === '') {
    return '';
  }
  return Number(data).toLocaleString('en-US');
}

export function abbreviatedString({ key, front = 9, end = 6, ellipsis = '······' }: {key: string, front?: number, end?: number, ellipsis?: string}) {
  if (key.length <= front + end) {
    return key;
  }
  return `${key.slice(0, front)}${ellipsis}${key.slice(-end)}`;
}
