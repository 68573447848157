import React, { useState } from 'react';
import { Button, Modal } from 'tea-component';
import style from './index.module.scss';

export default function ConfirmModal({
  title,
  message,
  visible,
  onSubmit,
  onClose,
  submitText,
  cancelText,
}: {
  title: string;
  message?: string;
  visible: boolean;
  onSubmit: () => Promise<void>;
  onClose?: () => void;
  submitText?: string;
  cancelText?: string;
}) {
  const [submitLoading, setSubmitLoading] = useState(false);
  return (
    <Modal
      size={440}
      className={style.confirm}
      visible={visible}
      caption={title}
      onClose={onClose}
      disableCloseIcon={true}
    >
      {message && <Modal.Body>{message}</Modal.Body>}
      <Modal.Footer>
        <Button type="weak" onClick={onClose}>
          {cancelText || '取消'}
        </Button>
        <Button type="primary" onClick={()=>{
          setSubmitLoading(true);
          onSubmit().finally(() => setSubmitLoading(false));
        }} loading={submitLoading}>
          {submitText || '确定'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
