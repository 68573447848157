import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Bubble, Table, Text } from 'tea-component';
import { pageable } from 'tea-component/lib/table/addons';
import { columnsResizable, scrollable } from 'tea-component/es/table/addons';
import { formatTime, isISODateFormat } from '@utils/tools';
import { ConfirmModal, ConfirmModalHandle } from '@components/modal';
import { ExecutionResultResponse, ExecutionStatus } from '@src/types';

function ExecResultContainer({}, ref: any) {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<ExecutionResultResponse | null>(null);
  const confirmModalRef = useRef<ConfirmModalHandle>(null);
  const [defaultColumns, records] = useMemo(() => {
    if (data?.status !== 1) {
      return [[], []];
    }
    return [data?.succeeded.columns.map((item) => ({
      header: item,
      key: item,
      render: (record: any) => {
        const isDate = isISODateFormat(record[item]);
        const value = isDate ? formatTime(record[item]) : record[item];
        const isBig = value && value.length > 500; // 大点的数据内容采用弹窗展示

        if (isBig) {
          return <>
            <span className={'explorer-btn'} onClick={() => {
              confirmModalRef.current?.show({
                className: 'dbquery-run-result-modal',
                caption: item,
                body: record[item] as string,
                footerButtons: () => '',
              });
            }}>
              {
                value.substring(0, 500) + '...'}
            </span>
          </>;
        }
        return <div className={'tea-text-overflow'}><Bubble overlayClassName={'dbquery-run-result-bubble'}
          content={record[item]}>
          {
            value
          }
        </Bubble></div>;
      },
    })),
    data?.succeeded.result,
    ];
  }, [data]);

  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    setColumns(defaultColumns);
  }, [defaultColumns]);

  useImperativeHandle(ref, () => ({
    show: (data: ExecutionResultResponse) => {
      setVisible(true);
      setData(data);
    },
    execResult: data,
  }), [data]);

  if (!visible) {
    return null;
  }
  if (data?.status === ExecutionStatus.success) {

    if (records.length === 0) {
      return <Text theme={'danger'}>
        很抱歉，查询不到您需要的数据
      </Text>;
    }
    return <>
      <ConfirmModal ref={confirmModalRef} />
      <Table columns={columns} records={records} addons={[
        columnsResizable({
          onResizeEnd: columns => {
            setColumns(columns);
          },
          minWidth: 100,
          maxWidth: 1000,
        }),
        scrollable({
          minWidth: defaultColumns.length * 100,
          virtualizedOptions: {
            itemHeight: 50,
            height: 300,
          },
        }),
        pageable(),
      ]}
      bordered={true}
      /></>;
  }
  if (data?.status === ExecutionStatus.fail) {
    return <>
      <Text theme={'danger'}>
        {data?.failed.message}
      </Text>
    </>;
  }
}


// @ts-ignore
export const ExecExecResult = React.forwardRef(ExecResultContainer);

