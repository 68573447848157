import React, { useEffect, useMemo, useState } from 'react';
import liststyle from '../../list.module.scss';
import { Table, TableColumn, Justify } from 'tea-component';
import { FTPositionItem, NFTPositionItem } from '@src/models';
import { GetFTPositionList, GetNFTPositionList } from '@src/utils/apis';
import { Link } from 'react-router-dom';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import SearchInput from '@components/ui/SearchInput';
import { getAllUrlParams } from '@src/utils/tools';
import { ResponseList } from '@src/utils/apis/type';
const { pageable } = Table.addons;

export function PositionList() {
  const { ctype = '' } = getAllUrlParams();
  const { contractAddr = '' } = useParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [list, setList] = useState<(FTPositionItem | NFTPositionItem)[]>([]);
  const [searchOwnerAddr, setSearchOwnerAddr] = useState<string>('');
  const [searchClickCount, setSearchClickCount] = useState<number>(0);

  const getListCallback = (res: ResponseList<FTPositionItem> | ResponseList<NFTPositionItem>) => {
    if (res.GroupList) {
      setList(res.GroupList);
      setRecordCount(res.TotalCount);
    }
  };

  const getList = () => {
    const params = {
      Offset: pageIndex - 1,
      ChainId: chainId || '',
      Limit: pageSize,
      ContractAddr: contractAddr,
      OwnerAddr: searchOwnerAddr,
    };
    if (ctype === 'nft') {
      GetNFTPositionList(params).then(getListCallback);
    } else if (ctype === 'ft') {
      GetFTPositionList(params).then(getListCallback);
    }
  };
  useEffect(() => {
    getList();
  }, [contractAddr, chainId, pageIndex, pageSize, searchClickCount]);
  const columns = useMemo<TableColumn[]>(() => {
    const list: TableColumn<FTPositionItem>[] = [
      {
        key: 'HoldRank',
        header: '排名',
        align: 'left',
        width: 180,
      },
      {
        key: 'OwnerAddr',
        header: '账户地址',
        align: 'left',
        render: ({ OwnerAddr }) => <Link to={`/${chainId}/chainaccount/deal?accountaddress=${OwnerAddr}`}>{OwnerAddr}</Link>,
      },
      {
        key: 'Amount',
        header: '持有数量',
        align: 'left',
        width: 300,
      },
      {
        key: 'HoldRatio',
        header: '占比',
        align: 'left',
        width: 180,
      },
    ];
    return list;
  }, []);
  return (
    <>
      <Justify
        style={{ marginTop: '20px' }}
        right={
          <SearchInput
            placeholder="输入账户地址搜索"
            onChange={(value) => setSearchOwnerAddr(value)}
            onSubmit={() => {
              setPageIndex(1);
              setSearchClickCount(searchClickCount + 1);
            }}
            value={searchOwnerAddr}
            bt={'筛选'}
          />
        }/>
      <Table
        className={liststyle.table}
        compact={false}
        records={list}
        recordKey="OwnerAddr"
        bordered={true}
        disableTextOverflow={true}
        columns={columns}
        addons={[
          pageable({
            recordCount,
            onPagingChange: (query) => {
              if (query?.pageIndex) {
                setPageIndex(query.pageIndex);
              }
              if (query?.pageSize) {
                setPageSize(query.pageSize);
              }
            },
          }),
        ]}
      />
    </>
  );
}
