import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../../list.module.scss';
import { Text, Table, TableColumn } from 'tea-component';
import { GetContractVersionList } from '@src/utils/apis';
import { Link } from 'react-router-dom';
import { GetContractVersionListItem } from '@src/models';
import { formatUnixTime } from '@src/utils/tools';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
import { UserAddress } from '@src/components/ui/AttrShow/UserAddress';
// import { formatUnixTime } from '@src/utils/tools';
const { pageable } = Table.addons;

export default function Transaction({ name }: { name?: string }) {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [list, setList] = useState<GetContractVersionListItem[]>([]);
  const getList = useCallback(() => {
    if (!name) {
      return;
    }
    GetContractVersionList({
      Offset: pageIndex - 1,
      ChainId: chainId,
      ContractName: name,
      Limit: pageSize,
    }).then((res) => {
      if (res.GroupList) {
        setList(res.GroupList);
        setRecordCount(res.TotalCount);
      }
    });
  }, [chainId, name, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [chainId, name, pageIndex, pageSize]);
  const columns = useMemo<TableColumn[]>(() => {
    const list: TableColumn<GetContractVersionListItem>[] = [
      {
        key: 'TxId',
        header: '交易Id',
        align: 'left',
        width: 300,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>{TxId}</Link>,
      },
      // {
      //   key: 'SenderOrgId',
      //   header: '发起组织',
      //   align: 'left',
      // },
      {
        key: 'Sender',
        header: '发起用户',
        align: 'left',
        render:({SenderAddrBNS, Sender, SenderAddr}) => <UserAddress UserAddrBns={SenderAddrBNS} Sender={Sender} UserAddr={SenderAddr}/>
        // authType === 'permissionedwithcert'
        //   ? ({ Sender, SenderAddr }) => <Bubble content={SenderAddr}>{Sender}</Bubble>
        //   : ({ SenderAddr }) => SenderAddr || '--',
      },
      {
        key: 'Version',
        header: '升级后版本号',
        align: 'left',
        width: 140,
      },
      {
        key: 'Timestamp',
        header: '时间',
        align: 'left',
        width: 180,
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
      },
      {
        key: 'ContractResultCode',
        header: '执行结果',
        align: 'left',
        width: 100,
        render: ({ ContractResultCode }) => (
          <Text theme={ContractResultCode === 0 ? 'success' : 'danger'}>
            {ContractResultCode === 0 ? '成功' : '失败'}
          </Text>
        ),
      },
    ];
    if (authType === 'permissionedwithcert') {
      list.splice(1, 0, {
        key: 'SenderOrgId',
        header: '发起组织',
        align: 'left',
      });
    }
    return list;
  }, []);
  return (
    <Table
      className={liststyle.table}
      compact={false}
      records={list}
      recordKey="TxId"
      bordered={true}
      disableTextOverflow={true}
      columns={columns}
      addons={[
        pageable({
          recordCount,
          onPagingChange: (query) => {
            if (query?.pageIndex) {
              setPageIndex(query.pageIndex);
            }
            if (query?.pageSize) {
              setPageSize(query.pageSize);
            }
          },
        }),
      ]}
    />
  );
}
