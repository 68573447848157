import { RootReducer } from '@src/store';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Bubble } from 'tea-component';

export function UserAddress({
  UserAddrBns,
  Sender, 
  UserAddr
}:{
  UserAddrBns?: string;
  Sender?: string;
  UserAddr?: string;
}){
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  if(authType === 'permissionedwithcert'){
    return <Bubble content={UserAddr}><Link to={`/${chainId}/chainaccount/deal?accountaddress=${UserAddr}`}>{Sender}</Link></Bubble>;              
  } else {
    if(UserAddrBns){
      if(UserAddr){
        return <Bubble content={UserAddr}><Link to={`/${chainId}/chainaccount/deal?accountaddress=${UserAddr}`}>{UserAddrBns}</Link></Bubble>;
      }else{
        return <>{UserAddrBns}</>;
      }
    }
    if(UserAddr){
      return <Link to={`/${chainId}/chainaccount/deal?accountaddress=${UserAddr}`}>{UserAddr}</Link>;
    }
    return  <>--</>;
  }
}