
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { Form, Input, Switch } from 'tea-component';
import { Controller, useForm, useWatch } from 'react-hook-form';
import FileUpload from '../FileUpload';
import { getStatus } from '@src/utils/form';

interface NodeItem{
  Addr?: string;
  Tls?: boolean;
  OrgCA?: string;
  TLSHostName?: string;
}
export default forwardRef(function SubscribeNodeForm({
  onChange,
  nodeinfo,
  isStandby,
  authType,
}: {
  onChange: (value: NodeItem) => void;
  nodeinfo:NodeItem,
  isStandby:boolean,
  authType:string;
}, refs) {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValidating, isSubmitted }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      Addr: '',
      OrgCA: '',
      Tls: true,
      TLSHostName: 'chainmaker.org',
    }
  });
  const addr = useWatch({ control, name: 'Addr' });
  const orgCA = useWatch({ control, name: 'OrgCA' });
  const tLSHostName = useWatch({ control, name: 'TLSHostName' });
  const tls = useWatch({ control, name: 'Tls' });

  useEffect(() => {
    onChange({
      Addr: addr,
      OrgCA: orgCA,
      TLSHostName: tLSHostName,
      Tls: tls
    });
  }, [addr, orgCA, tLSHostName, tls]);
  const updateValue = useCallback((value:NodeItem) => {
    if (value.Addr !== addr) {
      setValue('Addr', value.Addr || '');
    }
    if (value.OrgCA !== orgCA) {
      setValue('OrgCA', value.OrgCA || '');
    }
    if (value.TLSHostName !== tLSHostName) {
      setValue('TLSHostName', value.TLSHostName || '');
    }
    if (value.Tls !== tls) {
      setValue('Tls', value.Tls || false);
    }
  }, [addr, orgCA, tLSHostName, tls]);
  useEffect(() => {
    updateValue(nodeinfo);
  }, [nodeinfo]);
  useImperativeHandle(refs, () => ({
    handle: async () => {
      let pass = false;
      await handleSubmit(() => {
        pass = true;
      })();
      return pass;
    }
  }));

  return (
    <Form
      layout="fixed"
      fixedLabelWidth="140px"
    >
      <Controller
        control={control}
        rules={{
          required: '请输入节点RPC地址'
        }}
        name="Addr"
        render={({ field, fieldState }) => (
          <Form.Item
            required
            label={isStandby ? '备用节点RPC地址' : '节点RPC地址'}
            tips="请输入要订阅的链所在的服务器外网IP地址，及对外开放的RPC端口。请注意不能填127.0.0.1，长安链默认的RPC端口为12301-12304。"
            status={getStatus({
              fieldState,
              isValidating,
              isSubmitted
            })}
            message={fieldState.error?.message}
          >
            <Input {...field} placeholder="请输入节点RPC地址" />
          </Form.Item>)}
      />
      {authType !== 'permissionedwithcert' ||
      <>
        <Controller
          control={control}
          name="Tls"
          render={({ field, fieldState }) => (
            <Form.Item
              required
              label="是否开启TLS"
              status={getStatus({
                fieldState,
                isValidating,
                isSubmitted
              })}
              message={fieldState.error?.message}>
              <Switch {...field}>{tls ? '开启' : '关闭'}</Switch>
            </Form.Item>)}
        />
        {tls && (
          <>
            <Controller
              control={control}
              rules={{
                required: '请输入TLSHostName'
              }}
              name="TLSHostName"
              render={({ field, fieldState }) => (
                <Form.Item
                  required
                  label="TLSHostName"
                  tips="如无自定义修改过CA服务，则长安链默认的TLS_Host_Name为chainmaker.org。"
                  status={getStatus({
                    fieldState,
                    isValidating,
                    isSubmitted
                  })}
                  message={fieldState.error?.message}
                >
                  <Input {...field} placeholder="请输入TLSHostName" />
                </Form.Item>)}
            />
            <Controller
              control={control}
              rules={{
                required: '请上传节点所在组织证书'
              }}
              name="OrgCA"
              render={({ field, fieldState }) => (
                <Form.Item
                  required
                  label="节点所在组织证书"
                  status={getStatus({
                    fieldState,
                    isValidating,
                    isSubmitted
                  })}
                  message={fieldState.error?.message}>
                  <FileUpload fileName="节点所在组织证书" accept=".crt" {...field} />
                </Form.Item>)}
            />
          </>
        )}
      </>}
    </Form>
  );
});
