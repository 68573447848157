import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input, Justify, Table, TableColumn } from 'tea-component';
import { formatUnixTime } from '@utils/tools';
import liststyle from '@src/routes/main/list.module.scss';
import { Link } from 'react-router-dom';
import { GetIDADataList } from '@utils/apis';
import { useCurrentChainId } from '@utils/hooks';
import { useParams } from 'react-router';
import { ResponseList } from '@utils/apis/type';
import { IdaAssetIsDeletedMap } from '@src/routes/main/ida/constant';
import { autotip, pageable } from 'tea-component/es/table/addons';
import { Nullable } from '@src/types';

export default function IdaContractList() {
  const chainId = useCurrentChainId();
  const { contractAddr = '' } = useParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<ResponseList<{
    AssetCode: string;
    Creator: string;
    CreatedTime: number;
    UpdatedTime: number;
    IsDeleted: boolean;
  }>>({
    GroupList: [],
    TotalCount: 0,
  });
  const [queryParams, setQueryParams] = useState<{
    pageIndex: number;
    pageSize: number;
    contractAddr: string;
    assetCode?: Nullable<string>;
  }>({
    pageIndex: 1,
    pageSize: 10,
    contractAddr,
    assetCode: null,
  });
  const columns = useMemo<TableColumn[]>(() => {
    const list: TableColumn<{
      AssetCode: string;
      Creator: string;
      IsDeleted: boolean;
      CreatedTime: number;
      UpdatedTime: number;
    }>[] = [
      {
        key: 'AssetCode',
        header: '登记编码',
        align: 'left',
        render: ({ AssetCode }) => <Link to={{
          pathname: `../../contract/ida/${contractAddr}`,
          search: `?assetCode=${AssetCode}`,
        }}>{AssetCode}</Link>,
      },
      {
        key: 'Creator',
        header: '登记人',
        align: 'left',
        render: ({ Creator }) => <Link to={{
          pathname: '../../chainaccount/deal/',
          search: `?useraddr=${Creator}`,
        }}>{Creator}</Link>,
        width: 360,
      },
      {
        key: 'IsDeleted',
        header: '数据状态',
        align: 'left',
        width: 100,
        render: ({ IsDeleted }) => IdaAssetIsDeletedMap.get(IsDeleted),
      },
      {
        key: 'CreatedTime',
        header: '上链时间',
        align: 'left',
        render: ({ CreatedTime }) => formatUnixTime(CreatedTime),
        width: 180,
      },
      {
        key: 'UpdatedTime',
        header: '更新时间',
        align: 'left',
        render: ({ UpdatedTime }) => formatUnixTime(UpdatedTime),
        width: 180,
      },
      {
        key: 'Id',
        header: '操作',
        align: 'center',
        width: 80,
        render: ({ AssetCode }) => <Link to={{
          pathname: `../../contract/ida/${contractAddr}`,
          search: `?assetCode=${AssetCode}`,
        }}>查看</Link>,
      },
    ];
    return list;
  }, [contractAddr]);

  const onPressEnter = useCallback(() => {
    setQueryParams({
      ...queryParams,
      pageIndex: 1,
      assetCode: inputRef.current?.value.trim(),
    });
  }, [queryParams]);

  useEffect(() => {
    GetIDADataList({
      ChainId: chainId,
      Limit: queryParams.pageSize,
      Offset: queryParams.pageIndex - 1,
      ContractAddr: queryParams.contractAddr,
      AssetCode: queryParams.assetCode,
    }).then(res => {
      setData(res);
    });
  }, [chainId, queryParams]);

  return (
    <>
      <Justify
        className={'tea-mt-5n'}
        right={
          <>
            <Input ref={inputRef} onPressEnter={onPressEnter} placeholder={'请输入登记编码'}/>
            <Button type={'primary'} onClick={onPressEnter}>搜索</Button>
          </>
        }/>
      <Table
        className={liststyle.table}
        compact={false}
        records={data.GroupList}
        recordKey="ContractAddr"
        bordered={true}
        disableTextOverflow={true}
        columns={columns}
        addons={[
          autotip({
            emptyText: '暂无数据',
          }),
          pageable({
            recordCount: data.TotalCount,
            onPagingChange: (query) => {
              setQueryParams({
                ...queryParams,
                ...query,
              });
            },
          }),
        ]}
      />
    </>
  );
}
