import React, { useState, useEffect } from 'react';
import style from './index.module.scss';
import { CrossLatestTxList } from '@src/utils/apis';
import { useNavigate } from 'react-router-dom';
import { Table, Text } from 'tea-component';
import { CrossLatestTxListItem } from '@src/models';
import { useParams } from 'react-router-dom';
import { formatTime } from '@src/utils/tools';
import { CrossChainLink } from '@src/routes/main/components/CrossChainLink';

export function CrossLatestTx() {
  const { chainId } = useParams();
  const navigate = useNavigate();
  const [crossLatestTxList, setCrossLatestTxList] = useState<CrossLatestTxListItem[]>([]);
  useEffect(() => {
    if (chainId) {
      CrossLatestTxList({ ChainId: chainId }).then((res) => {
        setCrossLatestTxList(res.GroupList);
      });
    }
  }, [chainId]);

  return (
    <>
      <h3>最新跨链交易 <b onClick={()=> navigate(`/${chainId}/subchain/crossChainList`)}>更多{'>>'}</b></h3>
      <div className={style.news}>
        <Table
          className={style.detail_table}
          compact={false}
          records={crossLatestTxList}
          recordKey="CrossId"
          bordered={true}
          disableTextOverflow={true}
          columns={[
            {
              key: 'CrossId',
              header: '跨链ID',
              align: 'left',
              width: 100,
              render: (record: CrossLatestTxListItem) => {
                return <a href={`/${chainId}/subchain/crossTransactionDetail?crossId=${record.CrossId}`}>{record.CrossId}</a>;
              },
            },
            {
              key: 'FromChainName',
              header: '发起链名',
              align: 'left',
              render: (record: CrossLatestTxListItem) => {
                return <CrossChainLink chainId={chainId} subChainId={record.FromChainId} subIsMainChain={record.FromIsMainChain} name={record.FromChainName} />;
              },
            },
            {
              key: 'ToChainName',
              header: '接收链名',
              align: 'left',
              render: (record: CrossLatestTxListItem) => {
                return <CrossChainLink chainId={chainId} subChainId={record.ToChainId} subIsMainChain={record.ToIsMainChain} name={record.ToChainName} />;
              },
            },
            {
              key: 'Status',
              header: '跨链交易状态',
              align: 'left',
              width: 168,
              render: (record: CrossLatestTxListItem) => {
                if (record.Status === 0) {
                  return <Text>· 进行中</Text>;
                } else if (record.Status === 1) {
                  return <Text theme="success">· 成功</Text>;
                } else if (record.Status === 2) {
                  return <Text theme="danger">· 失败</Text>;
                }
              }
            },
            {
              key: 'Timestamp',
              header: '跨链发起时间',
              align: 'left',
              render: (record: CrossLatestTxListItem) => {
                return formatTime(record.Timestamp * 1000);
              },
            },
          ]}
        />
      </div>
    </>
  );
}
