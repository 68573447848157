import { SQLParamType } from '@src/types';
import { DatePicker, Form, Input, Select } from 'tea-component';
import { SQL_PARAM_OPTIONS } from '@src/routes/main/DBQuery/utils';
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

export function RunParams({ queryParams, onChange,noPermission }: {
  queryParams: {
    name: string;
    type: SQLParamType;
    value?: string;
  }[];
  onChange: (index: number, name: 'type' | 'value', value: string | number | string[]) => void;
  noPermission: boolean;
}) {
  return <Form className={classNames({
    'tea-mt-4n': queryParams.length,
    'dbquery-run-params': true,
  },
  )}>
    {
      queryParams.map((item, index) => {
        return <Form.Item label={item.name} key={item.name}>
          {
            item.type === SQLParamType.DateTime &&
            <DatePicker showTime value={item.value ? moment(+item.value) : moment()} onChange={(e) => {
              onChange(index, 'value', e.toDate().getTime());
            }} disabled={noPermission}/>
          }
          {
            item.type === SQLParamType.List && <Input value={item.value} onChange={(e) => {
              onChange(index, 'value', e);
            }} placeholder={'list类型数据则以逗号分隔'} disabled={noPermission}/>
          }
          {
            (item.type === SQLParamType.Number || item.type === SQLParamType.Text) &&
            <Input value={item.value} onChange={(e) => {
              onChange(index, 'value', e);
            }} disabled={noPermission}/>
          }
          <Select value={item.type.toString()} options={SQL_PARAM_OPTIONS} onChange={(e) => {
            if (+e === SQLParamType.DateTime) {
              onChange(index, 'value', Date.now());
            }
            onChange(index, 'type', +e);
          }} className={'tea-ml-1n'} disabled={noPermission}/>
        </Form.Item>;
      })
    }
  </Form>;
}
