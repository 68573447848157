import moment from 'moment';

export const formatTime = (date?: moment.MomentInput) => (date !== undefined ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '--');

export const formatUnixWithoutYear = (date?: number) =>
  date !== undefined ? moment.unix(date).format('MM-DD HH:mm:ss') : '--';

export const formatUnixTime = (date?: number) =>
  date !== undefined ? moment.unix(date).format('YYYY-MM-DD HH:mm:ss') : '--';

export const isISODateFormat = (dateString: string) => {
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;
  return iso8601Regex.test(dateString);
};
