import { ChainItem } from '@src/models';

export type ChainReducerState = {
  list: ChainItem[] | null;
  currentChain?: ChainItem;
};

export const chainReducer = (
  state: ChainReducerState = {
    list: null,
    currentChain: undefined,
  },
  action: {
    type: string;
    payload?: ChainItem[] | ChainItem;
  },
) => {
  if (action.type === 'UPDATE_CHAINS') {
    if (action.payload) {
      return {
        ...state,
        list: action.payload,
      };
    }
  }
  if (action.type === 'UPDATE_CURRENTCHAIN') {
    if (action.payload) {
      return {
        ...state,
        currentChain: action.payload,
      };
    }
  }
  return state;
};
