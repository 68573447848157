import React, { useEffect, useMemo, useState } from 'react';
import liststyle from '../../list.module.scss';
import { Table, TableColumn, Justify } from 'tea-component';
import { NFTTransferItem, FTTransferItem } from '@src/models';
import { GetFTTransferList, GetNFTTransferList } from '@src/utils/apis';
import { Link } from 'react-router-dom';
import { formatUnixTime, emptyToStrikethrough, getAllUrlParams } from '@src/utils/tools';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ResponseList } from '@src/utils/apis/type';
import SearchInput from '@components/ui/SearchInput';
const { pageable } = Table.addons;

export function NFTAndFTTransfer() {
  const { ctype = '' } = getAllUrlParams();
  const { contractAddr = '' } = useParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [list, setList] = useState<NFTTransferItem[] | FTTransferItem[]>([]);
  const [searchUserAddr, setSearchUserAddr] = useState<string>('');
  const [searchClickCount, setSearchClickCount] = useState<number>(0);

  const getListCallback = (res: ResponseList<NFTTransferItem> | ResponseList<FTTransferItem>) => {
    if (res.GroupList) {
      setList(res.GroupList);
      setRecordCount(res.TotalCount);
    }
  };

  const getList = () => {
    const params = {
      Offset: pageIndex - 1,
      ChainId: chainId,
      Limit: pageSize,
      ContractAddr: contractAddr,
      UserAddr: searchUserAddr,
    };

    if (ctype === 'nft') {
      GetNFTTransferList(params).then(getListCallback);
    } else if (ctype === 'ft') {
      GetFTTransferList(params).then(getListCallback);
    }
  };
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize, searchClickCount]);
  const columns = useMemo<(TableColumn | null)[]>(() => {
    const list: (TableColumn | null)[] = [
      {
        key: 'Timestamp',
        header: '上链时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
        width: 180,
      },
      {
        key: 'TxId',
        header: '交易Id',
        align: 'left',
        width: 220,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>{TxId}</Link>,
      },
      {
        key: 'ContractMethod',
        header: '合约方法',
        align: 'left',
        render: ({ ContractMethod }) => emptyToStrikethrough(ContractMethod),
      },
      {
        key: 'From',
        header: '从',
        align: 'left',
        render: ({ From }) => emptyToStrikethrough(From),
      },
      {
        key: 'To',
        header: '转到',
        align: 'left',
        render: ({ To }) => emptyToStrikethrough(To),
      },
      ctype === 'ft'
        ? {
          key: 'Amount',
          align: 'left',
          header: '数量'
        }
        : null,
      ctype === 'ft'
        ? {
          key: 'ContractSymbol',
          align: 'left',
          header: 'Token'
        }
        : null,
      ctype === 'nft'
        ? {
          key: 'ImageUrl',
          header: 'NFT信息',
          align: 'left',
          render: (record) => {
            return <div>{!!(record as NFTTransferItem).ImageUrl && <img width={50} src={(record as NFTTransferItem).ImageUrl} />}{(record as NFTTransferItem).TokenId}</div>;
          },
        }
        : null,
      {
        key: 'Setting',
        header: '操作',
        align: 'center',
        width: 100,
        render: (record) => {
          if (ctype === 'ft') {
            return <Link to={`/${chainId}/transaction/${record.TxId}`}>查看</Link>;
          } else if (ctype === 'nft') {
            return <Link to={`/${chainId}/nft?tokenId=${(record as NFTTransferItem).TokenId}&contractAddr=${contractAddr}`}>查看NFT</Link>;
          }
        },
      }
    ];
    return list;
  }, []);
  return (
    <>
      <Justify
        style={{ marginTop: '20px' }}
        right={
          <SearchInput
            placeholder="输入账户地址搜索"
            onChange={(value) => setSearchUserAddr(value)}
            onSubmit={() => {
              setPageIndex(1);
              setSearchClickCount(searchClickCount + 1);
            }}
            value={searchUserAddr}
            bt={'筛选'}
          />
        }/>
      <Table
        className={liststyle.table}
        compact={false}
        records={list}
        recordKey="TxId"
        bordered={true}
        disableTextOverflow={true}
        columns={columns.filter((col) => !!col) as TableColumn[]}
        addons={[
          pageable({
            recordCount,
            onPagingChange: (query) => {
              if (query?.pageIndex) {
                setPageIndex(query.pageIndex);
              }
              if (query?.pageSize) {
                setPageSize(query.pageSize);
              }
            },
          }),
        ]}
      />
    </>
  );
}
