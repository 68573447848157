import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PageBox from '@src/components/ui/PageBox';
import { Bubble, Button, Copy, Form, Input, Justify, Table, TableColumn, Text } from 'tea-component';
import { DeleteQueryTask, GetRegularQueryTaskList, SQLQueryServiceLogout } from '@src/utils/apis';
import { GetRegularQueryTaskListParam, GetRegularQueryTaskListResponse } from '@src/types';
import liststyle from '@src/routes/main/list.module.scss';
import { autotip, pageable } from 'tea-component/es/table/addons';
import { formatTime } from '@src/utils/tools';
import { useNavigate } from 'react-router';
import { ConfirmModal, ConfirmModalHandle } from '@components/modal';
import { AppContext } from '@components/context';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
import { usePluginLogin } from '@src/routes/main/DBQuery/hooks';
import classNames from 'classnames';
import { SQL_RUN_TYPE_DESC_MAP } from '@src/routes/main/DBQuery/utils';
import { Controller, useForm } from 'react-hook-form';

export default function DBQueryList() {
  const { account, setAccount } = useContext(AppContext);
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const confirmModalRef = useRef<ConfirmModalHandle>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<GetRegularQueryTaskListResponse>({
    total: 0,
    list: [],
  });
  const showType = account ? 'all' : 'public'; // 非登录用户只可以看到公开数据
  const navigate = useNavigate();

  const {
    control,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      userAddr: '',
    },
  });

  const [queryParam, setQueryParams] = useState<GetRegularQueryTaskListParam>({
    chainId: chainId as string, // 用户当前的链ID
    pageIndex: 0,
    pageSize: 10,
    show: showType,
    title: '',
    userAddr: '',
  });
  const { handleLoginClick } = usePluginLogin(confirmModalRef, () => {
    navigate(0);
  });

  const columns = useMemo<TableColumn[]>(() => {
    const list: TableColumn[] = [
      {
        key: 'title',
        header: '查询名称',
        align: 'left',
        render: ({ taskId, title }) => {
          return <a onClick={() => {
            navigate(`/${chainId}/dbQuery/${taskId}`);
          }}>{title}</a>;
        },
      },
      {
        key: 'isPublic',
        header: '是否公开',
        align: 'left',
        render: ({ isPublic }) => {
          return isPublic ? '公开' : '不公开';
        },
      },
      {
        key: 'runType',
        header: '定时执行',
        align: 'left',
        render: ({ runType }) => {
          return SQL_RUN_TYPE_DESC_MAP[runType];
        },
      },
      {
        key: 'taskUpdateTime',
        header: '任务更新时间',
        align: 'left',
        render: ({ taskUpdateTime }) => {
          return formatTime(taskUpdateTime);
        },
        width: 180,
      },
      {
        key: 'dataUpdateTime',
        header: '数据更新时间',
        align: 'left',
        render: ({ dataUpdateTime }) => {
          return formatTime(dataUpdateTime);
        },
        width: 180,
      },
      {
        key: 'userAddr',
        header: '账户地址',
        align: 'left',
        render: ({ userAddr }) => {
          return userAddr && <>{userAddr}<Copy text={userAddr}/></>;
        },
      },
    ];
    account && list.push(
      {
        key: 'Action',
        header: '操作',
        align: 'left',
        render: (record) => {
          return (
            <Text theme={record.isOwner ? 'danger' : 'label'} className={classNames('explorer-btn', {
              'is-disabled': !record.isOwner,
            })} onClick={() => {
              if (!record.isOwner) {
                return;
              }
              confirmModalRef.current?.show({
                caption: '是否删除该查询结果？',
                body: `查询名称：${record.title}`,
                onOK: () => {
                  DeleteQueryTask({
                    taskId: record.taskId,
                  }).then(() => {
                    setQueryParams({ ...queryParam });
                  });
                },
              });
            }}>
              <Bubble content={!record.isOwner && '非本人创建的查询不可以删除'} placement={'auto'}>
                删除
              </Bubble>
            </Text>
          );
        },
        width: 180,
      },
    );
    return list;
  }, [chainId, navigate, queryParam, account]);

  const handleLogout = useCallback(() => {
    SQLQueryServiceLogout({}).then(() => {
      setAccount(null, () => {
        setQueryParams({ ...queryParam, show: 'public' });
      });
    }).catch(() => {

    });
  }, [queryParam, setAccount]);

  const onPressEnter = useCallback(() => {
    const values = getValues();
    setQueryParams({
      ...queryParam,
      title: values.title.trim(),
      userAddr: values.userAddr.trim(),
      pageIndex: 1,
    });
  }, [getValues, queryParam]);

  useEffect(() => {
    setIsLoading(true);
    GetRegularQueryTaskList(queryParam).then((res) => {
      setData(res.data);
    }).catch(() => {
    }).finally(() => {
      setIsLoading(false);
    });
  }, [queryParam]);

  return (
    <PageBox title="SQL 自定义查询" backURL={'../'}>
      {<Justify
        left={
          <div className={'display-flex-center'}>
            {
              account && <>
                <Button onClick={() => {
                  navigate('./new');
                }}>新建查询表
                </Button>
                <a onClick={handleLogout} className={'tea-ml-2n explorer-link'}>登出
                </a>
                <Text theme={'label'} className={'base-font tea-ml-2n'}>
                  <Bubble content={account.address}>
                    {
                      account.name
                    }
                  </Bubble>
                </Text>
              </>
            }
            {
              !account && <a onClick={handleLoginClick} className={'tea-ml-2n explorer-link'}>登录</a>
            }
          </div>
        }
        right={
          <>
            <Form hideLabel={false} layout="inline" className={'form-fixed-line-height'}>
              <Form.Item label="名称">
                <Controller
                  control={control}
                  render={({ field }) => <Input placeholder={'请输入名称'} {...field} onPressEnter={onPressEnter} />}
                  name={'title'}/>
              </Form.Item>
              <Form.Item label="账户地址">
                <Controller
                  control={control}
                  render={({ field }) => <Input placeholder={'请输入账户地址'} {...field} onPressEnter={onPressEnter}/>}
                  name={'userAddr'}/>
              </Form.Item>
              <Button type={'primary'} onClick={() => {
                onPressEnter();
              }} className={'tea-ml-2n'}>搜索
              </Button>
            </Form>
          </>
        }
      />}
      <Table
        className={liststyle.table}
        compact={false}
        records={data.list}
        recordKey="queryId"
        bordered={true}
        disableTextOverflow={true}
        columns={columns}
        addons={[
          autotip({
            isLoading,
          }),
          pageable({
            recordCount: data.total,
            onPagingChange: (query) => {
              query.pageIndex && setQueryParams({
                ...queryParam,
                pageSize: query.pageSize!,
                pageIndex: query.pageIndex,
              });
            },
          }),
        ]}
      />
      <ConfirmModal ref={confirmModalRef}/>
    </PageBox>
  );
}


