import React from 'react';
import { Form } from 'tea-component';

/**
 * 表单项渲染
 */
export function FormItemRender({ title, value, valueClassName, children }: {
  title: React.ReactNode;
  value?: any;
  valueClassName?: string;
  children?: React.ReactNode;
}) {
  return <Form.Item label={title}>
    {
      children || <Form.Text className={valueClassName}>{value || '-'}</Form.Text>
    }
  </Form.Item>;
}
