import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PageBox from '@src/components/ui/PageBox';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
import { Button, Icon, Justify, notification, Text } from 'tea-component';
import {
  CancelExecution,
  ExecuteQuery,
  GetExecutionResult,
  GetQueryTaskDetail,
  GetQueryTaskResult,
  SaveQuerySql,
} from '@src/utils/apis';
import {
  ExecutionResultResponse,
  ExecutionStatus,
  GetDatabaseListResponse,
  SaveTaskParams,
  SQLParamType,
  SQLRunType,
} from '@src/types';
import style from './detail.module.scss';
import * as monaco from 'monaco-editor';
import { useParams } from 'react-router-dom';
import { AppContext } from '@components/context';
import '@utils/editor';
import { extractParamsByContent, formatQueryParamsValue } from '@src/routes/main/DBQuery/utils';
import { RunParams } from '@src/routes/main/DBQuery/run-params';
import { SaveTaskModal } from '@src/routes/main/DBQuery/save-task';
import { Sidebar } from '@src/routes/main/DBQuery/sidebar';
import { keyBy } from '@utils/utils';
import { ExecExecResult } from '@src/routes/main/DBQuery/run-result';
import { Base64 } from 'js-base64';

export default function DBQueryCreate() {
  const { account } = useContext(AppContext);
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const editorElRef = useRef<any>(null);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
  const saveTaskModal = useRef<{ show: () => void }>(null);
  const execResultRef = useRef<{
    show: (data: { failed: { type: number; message: string }; status: ExecutionStatus }) => void,
    execResult: ExecutionResultResponse | null
  }>(null);
  const execCostRef = useRef<{
    status?: 'executing' | 'canceling' | 'done'
    startTimestamp?: number;
  }>({}); // 执行耗时
  const params = useParams<{ taskId: string }>();
  const taskIdRef = useRef<string | undefined>(params.taskId);
  const [dbType, setDBType] = useState<GetDatabaseListResponse['dbType'] | null>(null);

  const [runTaskParams, setRunTaskParams] = useState<SaveTaskParams>({
    queryId: null,
    title: '',
    runType: SQLRunType.hour24Per,
    isPublic: true,
    chainId: chainId as string,
    isOwner: true,
  },
  );
  const noPermission = !account || !runTaskParams.isOwner; // 没有登录的用户/不是owner的任务
  const [queryParams, setQueryParams] = useState<{
    name: string;
    type: SQLParamType;
    value?: any;
  }[]>([]);
  const [execLoading, setExecLoading] = useState(false); // 执行中
  const [runDisabled, setRunDisabled] = useState(true); // 运行按钮禁用状态
  const handleSaveTask = useCallback(async () => {
    if (!runTaskParams.queryId) {
      notification.error({
        title: '无法保存任务',
        description: '请先执行查询',
        unique: true,
      });
      return;
    }
    if (!(execResultRef.current?.execResult?.status === ExecutionStatus.success)) {
      notification.error({
        title: '无法保存任务',
        description: '请先确保执行成功',
        unique: true,
      });
      return;
    }
    saveTaskModal.current?.show();
  }, [runTaskParams.queryId]);

  const getExecTaskResult = useCallback((taskId: string, useCache = false) => {
    setExecLoading(true);
    if (execCostRef.current.status === 'canceling' && (Date.now() - (execCostRef.current.startTimestamp!)) > 30_000) {
      setExecLoading(false);
      execResultRef.current?.show({
        status: ExecutionStatus.fail,
        failed: {
          type: 3,
          message: '查询被取消',
        },
      });
      return;
    }
    (useCache ? GetQueryTaskResult : GetExecutionResult)({ taskId }).then(res => {
      if (res.data.status === ExecutionStatus.ing) {
        setTimeout(() => {
          getExecTaskResult(taskId, useCache);
        }, 3_000);
        return;
      }
      execCostRef.current = {
        status: 'done',
      };
      setExecLoading(false);
      execResultRef.current?.show(res.data);
    }).catch(e => {
      console.error(e);
      setExecLoading(false);
    });
  }, []);

  const getExecTaskResultRef = useRef<(taskId: string, useCache?: boolean) => void>();
  getExecTaskResultRef.current = getExecTaskResult;

  const handleTableParamClick = useCallback((p: string) => {
    if (!account) {
      return;
    }
    const editor = editorRef.current;
    if (!editor) {
      return;
    }
    const position = editor.getPosition(); // 获取当前光标位置
    if (!position) {
      return;
    }
    editor.executeEdits('db-params', [
      {
        range: new monaco.Range(position.lineNumber, position.column, position.lineNumber, position.column),
        text: p,
        forceMoveMarkers: true,
      }]);
    editor.focus();
  }, [account]);


  /**
   * 开启查询
   * 1. 执行SQL创建
   * 2. 执行异步任务查询
   */
  const handleRunClick = useCallback(async () => {
    setExecLoading(true);
    try {
      const querySql = editorRef.current!.getValue();
      const saveQueryRes = await SaveQuerySql({
        queryId: runTaskParams.queryId || 0,
        querySql: Base64.encode(querySql),
        queryParams: queryParams.map(item => ({
          name: item.name,
          type: item.type,
        })),
      });
      setRunTaskParams(state => ({
        ...state,
        queryId: saveQueryRes.data.queryId,
      }));
      const { data: { taskId } } = await ExecuteQuery({
        queryId: saveQueryRes.data.queryId,
        queryParams: queryParams.map(item => ({
          name: item.name,
          value: formatQueryParamsValue(item),
        })),
      });
      execCostRef.current = {
        status: 'executing',
        startTimestamp: Date.now(),
      };
      taskIdRef.current = taskId;
      getExecTaskResultRef.current?.(taskId);
    } catch (e) {
      console.error(e);
      setExecLoading(false);
    }
  }, [runTaskParams.queryId, queryParams]);


  const handleRunningCancel = useCallback(() => {
    if (taskIdRef.current) {
      CancelExecution({
        taskId: taskIdRef.current,
      }).then(() => {
        execCostRef.current = {
          status: 'canceling',
          startTimestamp: Date.now(),
        };
      });
    }
  }, []);

  useEffect(() => {
    const editor = monaco.editor.create(editorElRef.current, {
      autoIndent: 'full',
      automaticLayout: true,
      contextmenu: false,
      language: 'sql',
      minimap: { enabled: false },
      quickSuggestions: true,
      readOnly: true,
      formatOnPaste: false,
      tabCompletion: 'on',
      scrollbar: {
        verticalScrollbarSize: 10,
        horizontalScrollbarSize: 10,
      },
      scrollBeyondLastLine: false,
      theme: 'vs-dark',
      autoClosingBrackets: 'always',
      autoClosingOvertype: 'always',
      autoClosingQuotes: 'always',
    });
    editorRef.current = editor;
    editor.getModel()?.onDidChangeContent(() => {
      const newParams = extractParamsByContent(editor.getValue()).map(item => ({
        name: item,
        type: SQLParamType.Text,
      }));
      setQueryParams(oldParams => {
        const oldParamsObj = keyBy(oldParams, 'name');
        return (newParams.map(item => ({
          name: item.name,
          type: oldParamsObj[item.name]?.type ?? item.type,
          value: oldParamsObj[item.name]?.value,
        })));
      });
    });
    return () => editor.dispose();
  }, []);


  useEffect(() => {
    editorRef.current?.updateOptions({
      readOnly: noPermission,
    });
  }, [noPermission]);

  useEffect(() => {
    // 访问查询任务详情时，加载已保存的任务SQL
    if (params.taskId) {
      taskIdRef.current = params.taskId;
      GetQueryTaskDetail({
        taskId: params.taskId,
      }).then(res => {
        editorRef.current!.setValue(res.data.querySql);
        setRunTaskParams(state => ({
          ...state,
          queryId: res.data.queryId,
          title: res.data.title,
          isPublic: res.data.isPublic,
          runType: res.data.runType,
          isOwner: res.data.isOwner,
        }));
        setQueryParams(res.data.queryParams || []);
        getExecTaskResultRef.current?.(params.taskId as string, true);
      });
    }
  }, [params.taskId]);

  useEffect(() => {
    setRunDisabled(!Boolean(editorRef.current?.getValue()) || queryParams.some(item => !item.value));
  }, [queryParams]);

  return (
    <PageBox title={runTaskParams.title || 'SQL 自定义查询'} backURL={`/${chainId}/dbQuery`}>
      <SaveTaskModal ref={saveTaskModal} queryParams={queryParams} runTaskParams={runTaskParams}/>
      <Justify left={<Text className={'base-font'}>
        数据库
        {dbType && <>（{dbType}）</>}
      </Text>} right={(account && runTaskParams.isOwner) && <Button onClick={handleSaveTask}>
        保存查询结果
      </Button>}/>
      <div className={style.container}>
        <div>
          <Sidebar handleTableParamClick={handleTableParamClick} setDBType={setDBType}/>
        </div>
        <div>
          <div className={'monaco-editor-custom'} ref={editorElRef}/>
          <div className={'monaco-editor-toolbar'}>
            <div>
              <Button onClick={() => {
                editorRef.current?.updateOptions({
                  readOnly: false,
                });
                editorRef.current?.getAction('editor.action.formatDocument')?.run().then(() => {
                  editorRef.current?.updateOptions({
                    readOnly: noPermission,
                  });
                });
              }} tooltip={'格式化SQL'}>
                <Icon type="multi-line"/>
              </Button>
              {
                !noPermission && <Button className={'tea-ml-2n'} onClick={() => {
                  handleTableParamClick('{{unnamed_parameter}}');
                }} tooltip={'添加参数'}>
                  <Icon type="plus"/>
                </Button>
              }
            </div>
            <div>
              {
                (!noPermission && execLoading) &&
                <Button className={'tea-mr-1n'} onClick={handleRunningCancel}>
                  取消
                </Button>}
              {
                noPermission
                  ? <>
                    {
                      execLoading && <Icon type="loading" className={'tea-mr-4n'}/>
                    }
                  </>
                  : <Button loading={execLoading} onClick={handleRunClick} disabled={runDisabled}>运行
                    <Icon type="arrowright"/>
                  </Button>
              }
            </div>
          </div>
          <RunParams queryParams={queryParams} onChange={(index, name, value) => {
            queryParams[index][name] = value;
            setQueryParams([...queryParams]);
          }} noPermission={noPermission}/>
          <div className={'tea-mt-4n'}>
            <ExecExecResult ref={execResultRef}/>
          </div>
        </div>
      </div>
    </PageBox>);
}
