import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@src/components/ui/PageBox';
import { Bubble, Form, Justify, Select, Table, TableColumn } from 'tea-component';
import SearchInput from '@components/ui/SearchInput';
import { UserItem } from '@src/models';
import { GetOrgList, GetUserList } from '@src/utils/apis';
import { formatUnixTime } from '@src/utils/tools';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
const { pageable, autotip } = Table.addons;

export default function Chain() {
  // const navigate = useNavigate();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const dispatch = useDispatch();
  const [options, setOptions] = useState<any[]>([]);
  const { value, orgId, pageIndex, pageSize } = useSelector((state: RootReducer) => state.userListParamReducer);
  const updateparam = useCallback((payload) => {
    dispatch({
      type: 'UPDATE_USERLIST_PARAM',
      payload,
    });
  }, []);
  const [list, setList] = useState<UserItem[]>([]);
  const [recordCount, setRecordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetUserList({
      ChainId: chainId,
      UserIds: value,
      OrgId: orgId,
      Limit: pageSize,
      Offset: pageIndex - 1,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, orgId, value, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize]);

  const onSearch = useCallback(() => {
    updateparam({ pageIndex: 1 });
    getList();
  }, [getList]);
  useEffect(() => {
    if (authType !== 'permissionedwithcert') {
      return;
    }
    GetOrgList({
      ChainId: chainId,
      Offset: 0,
      Limit: 9999,
    }).then((res) => {
      if (res.GroupList) {
        setOptions(
          res.GroupList.map((item) => ({
            value: item.OrgId,
            text: item.OrgId,
          })),
        );
      }
    });
  }, [authType]);
  const columns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'UserId',
        header: '用户名称',
        align: 'left',
        render:({ UserId, UserAddr }) => (UserAddr ? <Link to={`/${chainId}/chainaccount/deal/?useraddr=${UserAddr}`}><Bubble content={UserAddr}>{UserId}</Bubble></Link> : UserId || '--')
        // authType === 'permissionedwithcert'
        //   ? ({ UserId, UserAddr }) => (UserAddr ? <a href={`/${chainId}/chainaccount/deal/?useraddr=${UserAddr}`}><Bubble content={UserAddr}>{UserId}</Bubble></a> : UserId || '--')
        //   : ({ UserId }) => UserId || '--',
      },
      {
        key: 'Role',
        header: '用户角色',
        align: 'left',
      },
      // {
      //   key: 'UserAddr',
      //   header: '用户地址',
      //   align: 'left',
      //   render: ({ UserAddr }) => <Link to={`/${chainId}/chainaccount/deal?accountaddress=${UserAddr}`}>{UserAddr}</Link>,
      // },
      {
        key: 'OrgId',
        header: '所属组织',
        align: 'left',
      },
      {
        key: 'Timestamp',
        header: '创建时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
      },
    ],
    [],
  );
  return (
    <PageBox title="用户列表">
      <Justify
        right={
          <>
            {authType === 'permissionedwithcert' && (
              <Form className={liststyle.searchform} hideLabel={false} layout="inline">
                <Form.Item label="所属组织">
                  <Select
                    options={options}
                    clearable
                    matchButtonWidth
                    appearance="button"
                    size="m"
                    onChange={(value) => updateparam({ orgId: value })}
                    value={orgId}
                  />
                </Form.Item>
              </Form>
            )}
            <SearchInput
              placeholder="请输入用户名称搜索"
              onSubmit={onSearch}
              onChange={(value) => updateparam({ value })}
              value={value}
            />
          </>
        }
      />
      <Table
        className={liststyle.table}
        compact={false}
        records={list}
        recordKey="UserId"
        bordered={true}
        disableTextOverflow={true}
        columns={columns}
        addons={[
          autotip({
            isLoading,
          }),
          pageable({
            recordCount,
            pageIndex,
            pageSize,
            onPagingChange: (query) => {
              if (query?.pageIndex) {
                updateparam({ pageIndex: query.pageIndex });
              }
              if (query?.pageSize) {
                updateparam({ pageSize: query.pageSize });
              }
            },
          }),
        ]}
      />
    </PageBox>
  );
}
