// @ts-ignore
import Cookies from 'js-cookie';

export type ChainMakerPluginAccount = {
  name: string;
  address: string;
  token: string;
};

export function getChainMakerPluginAccount(): null | ChainMakerPluginAccount {
  try {
    let str = Cookies.get('chain_maker_plugin_account');
    return str ? JSON.parse(str) : null;
  } catch {
    return null;
  }
}

export function setChainMakerPluginAccount(account: null | ChainMakerPluginAccount) {
  if (account) {
    Cookies.set('chain_maker_plugin_account', JSON.stringify(account), {
      expires: 7, // 7 days
      path: '/',
    });
  }
}

export function removeChainMakerPluginAccount() {
  return Cookies.remove('chain_maker_plugin_account');
}
