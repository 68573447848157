import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageBox from '@components/ui/PageBox';

/**
 * 版本检查
 * @constructor
 */
function VersionFooter() {
  const [upgradeVisible, setUpgradeVisible] = useState(false);
  const [latestVersion, setLatestVersion] = useState(null);
  const goto = useNavigate();
  useEffect(() => {
    axios.get('https://docs.chainmaker.org.cn/latest-version/chainmaker-explorer-web/version')
      .then((res) => {
        if (CHAIN_MAKER.version !== res.data.trim()) {
          setLatestVersion(res.data.trim());
          setUpgradeVisible(true);
        }
      });
  }, []);
  return <>
    <div className={'explorer-footer-version'}>
      当前版本: v{CHAIN_MAKER.version}
    </div>
    {
      upgradeVisible && <div className="upgrade-icon" title="升级网站" onClick={() => {
        goto(`/upgrade?latestVersion=${latestVersion}`);
      }}></div>
    }
  </>;
}

function UpgradePage() {
  const [searchParams] = useSearchParams();
  const latestVersion = useMemo(() => searchParams.get('latestVersion') || CHAIN_MAKER.version, [searchParams]);
  return <>
    <PageBox title="系统更新提醒" backURL={'/'}>
      <div className={'tea-mt-4n'}>
        尊敬的开发者，浏览器v{latestVersion}版本已更新，请前往<a
          href={`https://docs.chainmaker.org.cn/v${latestVersion}/html/dev/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%B5%8F%E8%A7%88%E5%99%A8.html`}
          target={'_blank'} rel="noreferrer">长安链开源技术文档</a>更新部署。
      </div>
    </PageBox>
  </>;
}

const Version = {
  Footer: VersionFooter,
  UpgradePage,
};

export default Version;
