import React, { useCallback } from 'react';
import style from './index.module.scss';
import { useNavigate } from 'react-router-dom';

export default function PageBox({ title, children, backURL }: {
  title: string;
  children: React.ReactNode;
  backURL?: string
}) {
  const navigate = useNavigate();
  const navBack = useCallback(() => {
    if(backURL){
      navigate(backURL);
      return;
    }
    const { history } = window;
    if (history.length > 1) {
      history.back();
    } else {
      navigate('/');
    }
  }, [backURL, navigate]);
  return (
    <div className={style.box}>
      <div className={style.back}>
        <div className={style.left} onClick={navBack}></div>
        {title}
      </div>
      {children}
    </div>
  );
}
