import React, { useEffect, useState } from 'react';
import './component.scss';
// import { Table, TableColumn } from 'tea-component';
import { Justify, Bubble, Pagination } from 'tea-component';
import SearchInput from '@components/ui/SearchInput';
import { MediaComponent } from '@components/ui/Media';
import { NFTItem } from '@src/models';
import { GetNFTList } from '@src/utils/apis';
import { useParams } from 'react-router-dom';
import { abbreviatedString } from '@src/utils/tools';
// import monkey from '@imgs/monkey.webp';
import fanstasy from '@imgs/fantasy.webp';

export default function Nft() {
  const { chainId, contractAddr } = useParams();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [searchTokenId, setSearchTokenId] = useState<string>('');
  const [searchClickCount, setSearchClickCount] = useState<number>(0);
  const [nftList, setNftList] = useState<NFTItem[]>([]);

  const getNFTList = () => {
    GetNFTList({
      Offset: pageIndex - 1,
      Limit: pageSize,
      ChainId: chainId || '',
      ContractKey: contractAddr,
      TokenId: searchTokenId,
    })
      .then((res: any) => {
        if (res) {
          setNftList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      });
  };
  useEffect(() => {
    getNFTList();
  }, [chainId, pageIndex, pageSize, searchClickCount]);
  const onSearch = () => {
    setPageIndex(1);
    setSearchClickCount(searchClickCount + 1);
  };
  return (
    <>
      <div className="search-nft">
        <Justify
          right={
            <SearchInput
              placeholder="输入TokenID搜索"
              onChange={(value) => {
                setSearchTokenId(value);
              }}
              onSubmit={onSearch}
              value={searchTokenId}
              bt={'搜索'}
            />
          }/>
      </div>
      <div className="nft-List">
        {
          nftList && (nftList.map((item) => (
            // eslint-disable-next-line react/jsx-key
            <div className="nft-item" key={`${item.TokenId}${item.ContractAddr}`}>
              <a href={`/${chainId}/nft?tokenId=${item.TokenId}&contractAddr=${contractAddr}`}>
                <div className="nft-img">
                  <MediaComponent fileUrl={item?.ImageUrl || fanstasy} urlType={item?.UrlType} />
                </div>
                <p>
                  <span>Token ID：</span> <b> {item.TokenId} </b>
                </p>
              </a>
              <a href={`/${chainId}/chainaccount/deal?accountaddress=${item.OwnerAddr}`}>
                <p>
                  <span> Owner： </span> <b> <Bubble content={item.OwnerAddr}>{abbreviatedString({ key: item.OwnerAddr })}</Bubble> </b>
                </p>
              </a>
            </div>
          ))
          )
        }
      </div>
      <Pagination
        stateTextVisible={true}
        recordCount={recordCount}
        pageSize={pageSize}
        pageIndex={pageIndex}
        onPagingChange={(query) => {
          setPageIndex(query.pageIndex || 1);
          setPageSize(query.pageSize || 10);
        }}
      />
    </>
  );
}
