import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../../list.module.scss';
import { Text, Table, TableColumn, Justify, Select, Input, Button } from 'tea-component';
import { Tx } from '@src/models';
import { GetTxList } from '@src/utils/apis';
import { Link } from 'react-router-dom';
import { formatUnixTime } from '@src/utils/tools';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
import { TxStatusMap } from '@src/constant/index';
import { UserAddress } from '@src/components/ui/AttrShow/UserAddress';
// import SearchInput from '@components/ui/SearchInput';
const { pageable } = Table.addons;

export default function Transaction({ name }: { name?: string }) {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [list, setList] = useState<Tx[]>([]);
  const [searchType, setSearchType] = useState<string>('UserAddrs');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchClickCount, setSearchClickCount] = useState<number>(0);
  const getList = useCallback(() => {
    if (!name) {
      return;
    }
    const params: {
      Offset: number;
      ChainId: string | undefined;
      ContractName: string;
      Limit: number;
      UserAddrs?: string;
      ContractMethod?: string;
    } = {
      Offset: pageIndex - 1,
      ChainId: chainId,
      ContractName: name,
      Limit: pageSize,
    };

    if (searchType === 'UserAddrs') {
      params.UserAddrs = searchValue;
    }
    if (searchType === 'ContractMethod') {
      params.ContractMethod = searchValue;
    }
    GetTxList(params).then((res) => {
      if (res.GroupList) {
        setList(res.GroupList);
        setRecordCount(res.TotalCount);
      }
    });
  }, [chainId, name, pageIndex, pageSize, searchType, searchValue, searchClickCount]);
  
  useEffect(() => {
    if (!name) {
      return;
    }
    getList();
  }, [name, chainId, pageIndex, pageSize, searchClickCount]);


  const columns = useMemo<TableColumn[]>(() => {
    const list: TableColumn<Tx>[] = [
      {
        key: 'Timestamp',
        header: '上链时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
        width: 180,
      },
      {
        key: 'TxId',
        header: '交易Id',
        align: 'left',
        width: 220,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>{TxId}</Link>,
      },
      {
        key: 'Sender',
        header: '发起用户',
        align: 'left',
        render:({UserAddrBns,Sender, UserAddr}) => <UserAddress UserAddrBns={UserAddrBns} Sender={Sender} UserAddr={UserAddr}/>
        // authType === 'permissionedwithcert'
        //   ? ({ Sender, UserAddr }) => <Bubble content={UserAddr}>{Sender}</Bubble>
        //   : ({ UserAddr }) => UserAddr || '--',
      },
      {
        key: 'ContractMethod',
        header: '调用方法',
        align: 'left',
      },
      {
        key: 'TxStatus',
        header: '交易状态',
        align: 'left',
        width: 100,
        render: ({ TxStatus }) => (
          <Text theme={TxStatusMap[TxStatus].theme}>{TxStatusMap[TxStatus].text}</Text>
        ),
      },
      {
        key: 'Id',
        header: '操作',
        align: 'center',
        width: 100,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>查看</Link>,
      },
    ];
    if (authType === 'permissionedwithcert') {
      list.splice(2, 0, {
        key: 'SenderOrgId',
        header: '发起组织',
        align: 'left',
      });
    }
    return list;
  }, []);
  return (
    <>
      <Justify
        style={{ marginTop: '20px' }}
        right={
          <>
            <Select
              className='tea_cover_select'
              appearance="button"
              matchButtonWidth
              options={[
                {
                  value: 'UserAddrs',
                  text: '账户地址'
                },
                {
                  value: 'ContractMethod',
                  text: '调用方法'
                }
              ]}
              value={searchType}
              onChange={value => setSearchType(value)}
            />
            <Input className='tea_cover_input' placeholder="输入账户地址/调用方法搜索" value={searchValue} onChange={value => setSearchValue(value)} />
            <Button className='tea_cover_button_primary' onClick={() => {
              setPageIndex(1);
              setSearchClickCount(searchClickCount + 1);
            }}>筛选</Button>
          </>
        }/>
      <Table
        className={liststyle.table}
        compact={false}
        records={list}
        recordKey="TxId"
        bordered={true}
        disableTextOverflow={true}
        columns={columns}
        addons={[
          pageable({
            recordCount,
            onPagingChange: (query) => {
              if (query?.pageIndex) {
                setPageIndex(query.pageIndex);
              }
              if (query?.pageSize) {
                setPageSize(query.pageSize);
              }
            },
          }),
        ]}
      />
    </>
  );
}
