import React from 'react';

export const MediaComponent = ({ fileUrl, urlType }: { fileUrl: string; urlType: string }) => {
  if (urlType === 'image') {
    return <img src={fileUrl} alt="Image" />;
  } else if (urlType === 'video') {
    return (
      <video src={fileUrl} controls autoPlay={true}>
        <source src={fileUrl} type="video/*" />
      </video>
    );
  } else {
    return <div>Loading...</div>;
  }
};