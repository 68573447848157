
import { IdaAssetCategoryEnum, IdaAssetUpdateCycleTypeEnum } from '@src/types';

export const IdaAssetCategoryMap = {
  [IdaAssetCategoryEnum.none]: '无',
  [IdaAssetCategoryEnum.dataset]: '数据集',
  [IdaAssetCategoryEnum.api]: 'API服务',
  [IdaAssetCategoryEnum.dataReport]: '数据报告',
  [IdaAssetCategoryEnum.dataApp]: '数据应用',
  [IdaAssetCategoryEnum.compute]: '计算模型',
};


export const IdaAssetIsDeletedMap = new Map(
  [
    [true, '已删除'],
    [false, '正常'],
  ],
);

export const IdaAssetImmediatelySupplyMap = new Map([
  [true, '即时供应'],
  [false, '延迟供应'],
]);

export const IdaAssetUpdateCycleTypeMap = {
  [IdaAssetUpdateCycleTypeEnum.static]: '静态',
  [IdaAssetUpdateCycleTypeEnum.instant]: '实时',
  [IdaAssetUpdateCycleTypeEnum.period]: '周期',
  [IdaAssetUpdateCycleTypeEnum.other]: '其他',
};
